import { IField } from "../interfaces/inputs/IField";

const categories = [
  {value: 'assesment', text: 'Assessment'},
  {value: 'exam', text: 'Exam'},
  {value: 'test', text: 'Test'},
  {value: 'weekly', text: 'Weekly'},
  {value: 'lower work', text: 'Lower Work'},
  {value: 'monthly', text: 'Monthly'},
]

const statuses = [
  {value: 'active', text: 'Active'},
  {value: 'inactive', text: 'Inactive'}
]


export const MarktypeFields: IField[] = [
  { name: "label", type: "text", label: "Name", size: {lg: 6}, position: 1 },
  { name: "category", type: "select", label: "Category", options: categories, size: {lg: 6}, position: 2 },
  { name: "position", type: "number", label: "Position", size: {lg: 6}, position: 3 },
  { name: "status", type: "select", label: "Status", options: statuses, size: {lg: 6}, position: 4 },
].sort((a: IField, b: IField) => a.position - b.position);