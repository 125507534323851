import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Container,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Button,
} from "reactstrap";
import MetaTags from "react-meta-tags";

//Import Breadcrumb
import Breadcrumbs from "../../../components/common/Breadcrumb";
// import "../../../src/assets/scss/custom/plugins/_datatables.scss";
import { ApiCore } from "../../../helpers/api_helper";
import { Loading } from "../../../components/common/Loading";
import { AxiosResponse } from "axios";
import { Endpoints } from "../../../utils/Endpoints";
import { XTextBox } from "../../../components/common/inputs/XTextBox";
import { Form, Formik } from "formik";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { XSelect } from "../../../components/common/inputs/XSelect";
import { getForms } from "../../../utils/Helpers";
import { XCheckbox } from "../../../components/common/inputs/XCheckbox";
import { useHistory } from "react-router-dom";

const MarkTargets = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<any>({});
  const [marktargets, setMarkTargets] = useState<any>(null);
  const [totalRecords, setTotalRecords] = useState<number>(0);

  const history = useHistory();

  const request = new ApiCore();

  const terms = [
    { value: "I", text: "I" },
    { value: "II", text: "II" },
    { value: "III", text: "III" },
  ];

  const searchMarkTargets = (values: any) => {
    if (values.excel && values.excel == 1) {
      fetchFile(values);
    } else {
      fetchHtml(values);
    }
  };

  const fetchFile = (values: any) => {
    toast
      .promise(
        request.get(
          Endpoints.marktargets,
          {
            params: {
              form: values.form,
              term: values.term,
              year: values.year,
              stream: values.stream,
              subject_id: values.subject_id,
              excel: 1,
            },
          },
          "arraybuffer"
        ),
        {
          pending: "Please wait..",
          success: {
            render({ data }) {
              return "";
            },
          },
          error: {
            render({ data }) {
              return data;
            },
          },
        }
      )
      .then((response: AxiosResponse) => {
        const type = response.headers["content-type"];
        const blob = new Blob([response.data], { type: type });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `Mark_Targets-${+new Date()}.xlsx`;
        link.click();
      });
  };

  const fetchHtml = (values: any) => {
    toast
      .promise(
        request.get(Endpoints.marktargets, {
          params: values ,
        }),
        {
          pending: "Please wait..",
          success: {
            render({ data }) {
              return "";
            },
          },
          error: {
            render({ data }) {
              return data;
            },
          },
        }
      )
      .then((response: AxiosResponse) => {
        const { marktargets, totalRecords } = response.data;
        setMarkTargets(marktargets);
        setTotalRecords(totalRecords);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleSubmit = (values: any) => {
    searchMarkTargets(values);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Mark Targets - Treda</title>
        </MetaTags>

        <Container fluid>
          <Breadcrumbs title="Marks" breadcrumbItem="Mark Targets" />
          <Row>
            <Col md={12} lg={12} xl={12}>
              <Card>
                <CardHeader className="justify-content-between d-flex align-items-center">
                  <CardTitle className="h4">Mark Targets</CardTitle>

                  <UncontrolledDropdown className="mt-4 mt-sm-0">
                    <DropdownToggle tag="a" className="btn btn-light">
                      <i className="fa fa-ellipsis-v"></i>
                    </DropdownToggle>

                    <DropdownMenu className="dropdown-menu-end">
                      <DropdownItem
                        to="#"
                        onClick={() => {
                          history.push("/school/marktargets/create");
                        }}
                      >
                        Upload Marks
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </CardHeader>
                <CardBody>
                  {loading && <Loading label="Loading marks. Please wait." />}
                  {!loading && (
                    <>
                      <Row className="mb-2">
                        <Col xl="12">
                          <ToastContainer />
                        </Col>
                      </Row>
                      <Formik
                        initialValues={initialValues}
                        enableReinitialize
                        validateOnBlur
                        onSubmit={(values: any) => handleSubmit(values)}
                      >
                        {({
                          isSubmitting,
                          setFieldValue,
                          values,
                          errors,
                          touched,
                        }) => (
                          <>
                            <Form>
                              <Row>
                                <Col lg={2}>
                                  <XSelect
                                    key="form"
                                    id="form"
                                    name={"form"}
                                    options={getForms()}
                                    label={"Class"}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={2}>
                                  <XTextBox
                                    type="text"
                                    key="stream"
                                    id="stream"
                                    name={"stream"}
                                    label={"Stream"}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={2}>
                                  <XSelect
                                    key="term"
                                    id="term"
                                    name={"term"}
                                    options={terms}
                                    label={"Term"}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={1}>
                                  <XTextBox
                                    type="text"
                                    key="year"
                                    id="year"
                                    name={"year"}
                                    label={"Year"}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={2}>
                                  <XSelect
                                    key="subject_id"
                                    id="subject_id"
                                    name={"subject_id"}
                                    dataSrc={Endpoints.subjects}
                                    dataType={"subjects"}
                                    label={"Subject"}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={1}>
                                  <XCheckbox
                                    key="excel"
                                    id="excel"
                                    name={"excel"}
                                    label={"Excel"}
                                    value="1"
                                    includeTopPadding={true}
                                    onChange={setFieldValue}
                                    checked={values.excel == 1}
                                    size={{}}
                                  />
                                </Col>
                                <Col lg={2}>
                                  <div>&nbsp;</div>
                                  <Button
                                    disabled={false}
                                    type="submit"
                                    className="btn btn-primary"
                                  >
                                    Next
                                  </Button>
                                </Col>
                              </Row>
                              <hr />
                              {/* {marktargets && ( */}
                              <>
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th>Student</th>
                                      <th>Class</th>
                                      <th>Term</th>
                                      <th>Year</th>
                                      <th>Subject</th>
                                      <th>Target</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {marktargets &&
                                      marktargets.map((item: any) => {
                                        return (
                                          <>
                                            <tr key={item.id}>
                                              <td>
                                                {item.student
                                                  ? item.student.lastname +
                                                    " " +
                                                    item.student.firstname
                                                  : ""}
                                              </td>
                                              <td>{item.form}</td>
                                              <td>{item.term}</td>
                                              <td>{item.year}</td>
                                              <td>
                                                {item.subject
                                                  ? item.subject.name
                                                  : ""}
                                              </td>
                                              <td>{item.target}</td>
                                            </tr>
                                          </>
                                        );
                                      })}
                                  </tbody>
                                </table>
                              </>
                            </Form>
                          </>
                        )}
                      </Formik>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default MarkTargets;
