import { IField } from "../interfaces/inputs/IField";
import { Endpoints } from "../utils/Endpoints";
import { getForms } from "../utils/Helpers";


const terms = [
  {value: 'I', text: 'I'},
  {value: 'II', text: 'II'},
  {value: 'III', text: 'III'}
]

const forms = getForms();

export const SubjectteacherFields: IField[] = [
  { name: "subject_id", type: "select", label: "Subject", dataType: "subjects", dataSrc: Endpoints.subjects, size: {lg: 4}, position: 1 },
  { name: "user_id", type: "select", label: "Teacher", dataType: "users", dataSrc: Endpoints.users, size: {lg: 4}, position: 2 },
  { name: "form", type: "select", label: "Class", options: forms, size: {lg: 4}, position: 3 },
  { name: "stream", type: "text", label: "Stream", size: {lg: 4}, position: 4 },
  { name: "year", type: "text", label: "Year", size: {lg: 4}, position: 5 },
  { name: "term", type: "select", label: "Term", options: terms, position: 6, size: {lg: 4} },
].sort((a: IField, b: IField) => a.position - b.position);