import { IField } from "../interfaces/inputs/IField";
import { Endpoints } from "../utils/Endpoints";
import { getForms } from "../utils/Helpers";

const terms = [
  { value: "I", text: "I" },
  { value: "II", text: "II" },
  { value: "III", text: "III" },
];

const forms = getForms();

export const GradeFootsFields: IField[] = [
  {
    name: "form",
    type: "select",
    label: "Class",
    options: forms,
    size: { lg: 4 },
    position: 2,
  },
  {
    name: "details",
    type: "textarea",
    label: "Details",
    size: { lg: 8 },
    position: 3,
  },
].sort((a: IField, b: IField) => a.position - b.position);
