interface MenuItemsProps {
  id: number;
  novidade?: any;
  label: string;
  icon?: string;
  link?: string;
  badge?: string;
  badgecolor?: string;
  businessCategory?: string;
  subItems?: any;
  isHeader?: boolean;
  clazz?: string;
  ability?: string;
}

const menuItems: Array<MenuItemsProps> = [
  {
    id: 139,
    label: "Dashboard",
    icon: "chart-line",
    link: "/dashboard",
  },
  {
    id: 1,
    label: "General",
    isHeader: true,
  },
  {
    id: 138,
    label: "Students",
    icon: "users",
    link: "/#",
    subItems: [
      {
        id: 1,
        label: "Categories",
        link: "/students/categories",
        parentId: 138,
      },
      {
        id: 2,
        label: "Students",
        link: "/students/list",
        parentId: 138,
      },
    ],
  },
  {
    id: 142,
    label: "Marks",
    icon: "book",
    link: "/#",
    subItems: [
      {
        id: 1,
        label: "Categories",
        link: "/marks/categories",
        parentId: 141,
      },
      {
        id: 2,
        label: "Upload Marks",
        link: "/marks/create",
        parentId: 141,
        clazz: "School::Mark",
        ability: "create"
      },
      {
        id: 3,
        label: "Marks",
        link: "/school/marks",
        parentId: 141,
      },
      {
        id: 3,
        label: "Delete Marks",
        link: "/marks/delete",
        parentId: 141,
        clazz: "School::Mark",
        ability: "manage"
      },
      {
        id: 4,
        label: "Mark Sheet",
        link: "/marks/marksheet",
        parentId: 141,
        clazz: "School::Mark",
        ability: "manage"
      },
      {
        id: 4,
        label: "Average Mark Sheet",
        link: "/marks/avgmarksheet",
        parentId: 141,
        clazz: "School::Mark",
        ability: "manage"
      },
      {
        id: 5,
        label: "Upload Mark Targets",
        link: "/school/marktargets/create",
        parentId: 141,
        clazz: "School::Mark",
        ability: "manage"
      },{
        id: 5,
        label: "View Mark Targets",
        link: "/school/marktargets",
        parentId: 141,
        clazz: "School::Mark",
        ability: "manage"
      },{
        id: 5,
        label: "Publish Marks",
        link: "/school/publishmarks",
        parentId: 141,
        clazz: "School::Mark",
        ability: "manage"
      },
    ],
  },
  {
    id: 143,
    label: "Comments",
    icon: "comment",
    link: "/#",
    subItems: [
      {
        id: 1,
        label: "Categories",
        link: "/comments/categories",
        parentId: 143,
      },
      {
        id: 2,
        label: "Auto Subject Comments",
        link: "/comments/automarkcomments",
        parentId: 144,
      },
      {
        id: 3,
        label: "Auto Report Comments",
        link: "/comments/autoreportcomments",
        parentId: 144,
      },
      {
        id: 3,
        label: "Subject Comments",
        link: "/comments/subject",
        parentId: 144,
      },
      {
        id: 3,
        label: "Report Comments",
        link: "/comments/report",
        parentId: 144,
      },{
        id: 3,
        label: "Delete Comments",
        link: "/comments/delete",
        parentId: 144,
      },
    ],
  },
  {
    id: 144,
    label: "New Curriculum",
    icon: "book",
    link: "/#",
    businessCategory: "secondary",
    subItems: [
      {
        id: 1,
        label: "Competencies",
        link: "/school/topics",
        parentId: 144,
      },
      {
        id: 2,
        label: "Projects",
        link: "/school/projects",
        parentId: 144,
      },
      ,
      {
        id: 3,
        label: "Subject Comments",
        link: "/school/mark_newcurriculumcomment",
        parentId: 144,
      },
      {
        id: 4,
        label: "Generic Skills",
        link: "/school/mark_genericcomment",
        parentId: 144,
      },
      ,
      {
        id: 5,
        label: "Terminal Reports",
        link: "/school/ncterminalreport",
        parentId: 144,
      },
      {
        id: 6,
        label: "Mark Sheet",
        link: "/school/ncmarksheet",
        parentId: 144,
      },
      {
        id: 7,
        label: "Other Comments",
        link: "/school/ncreportremark",
        parentId: 144,
      },
    ],
  },
  {
    id: 1,
    label: "Reports",
    isHeader: true,
  },
  {
    id: 140,
    label: "Terminal Report",
    icon: "scroll",
    link: "/terminalreport",
  },{
    id: 51,
    label: "Cummulative Report",
    icon: "scroll",
    link: "/cummulativereport"
  },
  {
    id: 3,
    label: "Progressive Report",
    link: "/progressreport",
    icon: "scroll",
    clazz: "School::Mark",
    ability: "manage"
  },
  {
    id: 143,
    label: "General Statistics",
    icon: "chart-bar",
    link: "/otherstats",
  },
  {
    id: 143,
    label: "Result Analytics",
    icon: "chart-bar",
    link: "/resultanalysis",
  },
  {
    id: 144,
    label: "Grade Footer",
    icon: "th",
    link: "/school/gradefoots",
  },
  {
    id: 145,
    label: "Report Footer",
    icon: "th",
    link: "/school/reportfooters",
  },
  {
    id: 1,
    label: "Settings",
    isHeader: true,
  },
  {
    id: 140,
    label: "Grades",
    icon: "th",
    link: "/grades",
  },
  {
    id: 143,
    label: "Proportions",
    icon: "chart-pie",
    link: "/proportions",
  },
  {
    id: 144,
    label: "A-level scores",
    icon: "th",
    link: "/alevelscores",
  },
  {
    id: 141,
    label: "Subjects",
    icon: "layer-group",
    link: "/#",
    subItems: [
      {
        id: 1,
        label: "Subjects",
        link: "/subjects/list",
        parentId: 141,
      },
      {
        id: 2,
        label: "Subject Papers",
        link: "/school/subjectpapers",
        parentId: 141,
      },
    ],
  },
  {
    id: 140,
    label: "Class teachers",
    icon: "th",
    link: "/classteachers",
  },
  {
    id: 141,
    label: "Deputy Head Teachers",
    icon: "th",
    link: "/school/deputies",
  },
  {
    id: 140,
    label: "Subject teachers",
    icon: "th",
    link: "/subjects/teachers",
  },
];

export { menuItems };
