import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  CardHeader,
  CardTitle,
  Container,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Row,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../../components/common/Breadcrumb";

//Import Image
import logo from "../../assets/images/logo-sm.png";
import { ApiCore } from "../../../helpers/api_helper";
import { AxiosResponse } from "axios";
import { Endpoints } from "../../../utils/Endpoints";
import { Loading } from "../../../components/common/Loading";
import {
  amountToCurrency,
  formattedDate,
  currentTenant,
  fullUrl,
} from "../../../utils/Helpers";
import QRCode from "react-qr-code";
import XModal from "../../../components/common/XModal";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CreateForm from "../../../components/forms/CreateForm";

const CompetencyDetails = () => {
  const [competency, setCompetency] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [showEmail, setShowEmail] = useState<boolean>(false);
  const { id } = useParams();
  const history = useHistory();

  const request = new ApiCore();
  const tenant = currentTenant();

  useEffect(() => {
    toast
      .promise(request.get(Endpoints.topics + "/" + id), {
        pending: "Please wait..",
        success: {
          render({ data }) {
            return "";
          },
        },
        error: {
          render({ data }) {
            return data;
          },
        },
      })
      .then((response: AxiosResponse) => {
        setCompetency(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleOption = async (id: string, type: string) => {
    if (type == "edit") {
      history.push("/school/topics/new?id=" + id);
    } else if (type == "record") {
      history.push("/marks/create?topic_id=" + id);
    } else if (type == "view") {
      history.push("/school/marks?topic_id=" + id);
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content">
        <MetaTags>
          <title>Competency Detail | Treda</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Competencies"
            breadcrumbItem="Competency Detail"
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="justify-content-between d-flex align-items-center">
                  <CardTitle className="h4">
                    Competency #{competency.id}
                  </CardTitle>

                  <UncontrolledDropdown className="mt-4 mt-sm-0">
                    <DropdownToggle tag="a" className="btn btn-light">
                      <i className="fa fa-ellipsis-v"></i>
                    </DropdownToggle>

                    <DropdownMenu className="dropdown-menu-end">
                      <DropdownItem
                        to="#"
                        onClick={() => {
                          handleOption(competency.id, "edit");
                        }}
                      >
                        Edit Competency
                      </DropdownItem>
                      <DropdownItem
                        to="#"
                        onClick={() => {
                          handleOption(competency.id, "record");
                        }}
                      >
                        Record Marks
                      </DropdownItem>
                      <DropdownItem
                        to="#"
                        onClick={() => {
                          handleOption(competency.id, "view");
                        }}
                      >
                        View Marks
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </CardHeader>
                <CardBody>
                  {loading && (
                    <Loading label="Loading Competency. Please wait." />
                  )}
                  {!loading && (
                    <>
                      <div className="py-2">
                        <div className="table-responsive">
                          <table className="table align-middle table-nowrap table-centered mb-0">
                            <tbody>
                              <tr>
                                <td className="text-start">
                                  <h6 className="m-0 fw-semibold">Class :</h6>
                                </td>
                                <td>
                                  <h5 className="m-0 fw-normal">
                                    {competency.form}
                                  </h5>
                                </td>
                              </tr>
                              <tr>
                                <h6 className="m-0 fw-semibold">Term :</h6>
                                <td>
                                  <h5 className="m-0 fw-normal">
                                    {" "}
                                    {competency.term}
                                  </h5>
                                </td>
                              </tr>
                              <tr>
                                <h6 className="m-0 fw-semibold">Subject :</h6>
                                <td>
                                  <h5 className="m-0 fw-normal">
                                    {competency.subject_id}
                                  </h5>
                                </td>
                              </tr>
                              <tr>
                                <h6 className="m-0 fw-semibold">Title :</h6>
                                <td>
                                  <h6 className="m-0 fw-normal">
                                    {competency.title}
                                  </h6>
                                </td>
                              </tr>
                              <tr>
                                <h6 className="m-0 fw-semibold">Remarks :</h6>
                                <td>
                                  <h6 className="m-0 fw-normal">
                                    {competency.remarks}
                                  </h6>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CompetencyDetails;
