import { IField } from "../interfaces/inputs/IField";

const categories = [
  {value: 'general', text: 'General'},
  {value: 'nc skill', text: 'Nc Skill'},
  {value: 'nc general', text: 'Nc General'},
]

export const AutomarkcommentFields: IField[] = [
  { name: "score", type: "text", label: "Score", size: {lg: 12}, position: 1 },
  { name: "comment", type: "text", label: "Comment", size: {lg: 6}, position: 2 },
  { name: "category", type: "select", label: "Category", options: categories, size: {lg: 6}, position: 4 },
].sort((a: IField, b: IField) => a.position - b.position);