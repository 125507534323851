import React, { useEffect, useRef, useState } from "react";
import MetaTags from "react-meta-tags";

//import Breadcrumbs
import Breadcrumbs from "../../../components/common/Breadcrumb";
import { Loading } from "../../../components/common/Loading";

import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";

import { FieldArray, Form, Formik } from "formik";
import { XSelect } from "../../../components/common/inputs/XSelect";
import { XTextArea } from "../../../components/common/inputs/XTextArea";
import { XTextBox } from "../../../components/common/inputs/XTextBox";
import { InvoiceFields } from "../../../fields/InvoiceFields";
import { Endpoints } from "../../../utils/Endpoints";
import { useFocus } from "../../../hooks/useFocus";
import { ApiCore } from "../../../helpers/api_helper";
import { ISelectOption } from "../../../interfaces/inputs/ISelectOption";
import { XDatePickerField } from "../../../components/common/inputs/XDatePickerField";
import { XCheckbox } from "../../../components/common/inputs/XCheckbox";
import { AxiosError, AxiosResponse } from "axios";
import XModal from "../../../components/common/XModal";
import { SupplierFields } from "../../../fields/SupplierFields";
import { SupplierSchema } from "../../../schemas/SupplierSchema";
import CreateForm from "../../../components/forms/CreateForm";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { ISupplier } from "../../../interfaces/suppliers/ISupplier";
import { ProductFields } from "../../../fields/ProductFields";
import { ProductSchema } from "../../../schemas/ProductSchema";
import { IProduct } from "../../../interfaces/products/IProduct";
import { XSearchableSelect } from "../../../components/common/inputs/XSearchableSelect";
import { toast } from "react-toastify";
import { getTerms } from "../../../utils/Helpers";
import { XFileInput } from "../../../components/common/inputs/XFileInput";

const NewProject = () => {
  const [defaults, setDefaults] = useState<any>({});
  const [companies, setCompanies] = useState<any>([]);
  const [products, setProducts] = useState<any>([]);
  const [users, setUsers] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingProject, setloadingProject] = useState<boolean>(true);
  const [subjects, setSubjects] = useState<any>([]);
  const [classes, setClasses] = useState<any>([]);
  const params = new URLSearchParams(useLocation().search);
  const [project, setProject] = useState<any>(null);

  const history = useHistory();

  const request = new ApiCore();

  const projectId = params.get("id") ? params.get("id") : null;

  useEffect(() => {
    if (projectId) {
      request
        .get(Endpoints.projects + "/" + projectId)
        .then((response: AxiosResponse) => {
          setProject(response.data);
          setloadingProject(false);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, []);

  useEffect(() => {
    request
      .get(Endpoints.subjects, {})
      .then((response: AxiosResponse) => {
        const { subjects, totalRecords } = response.data;
        let options: any = [];
        options = subjects.map((data: any) => ({
          value: data.id,
          text: data.name,
          data: JSON.stringify(data),
        }));
        setSubjects(options);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    request
      .get(Endpoints.getSchoolFeesDefaults, {})
      .then((response: AxiosResponse) => {
        const { forms } = response.data;
        let options: any = [];
        options = forms.map((data: any) => ({
          value: data,
          label: data,
          data: JSON.stringify(data),
        }));
        setClasses(options);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    request
      .get(Endpoints.defaults)
      .then((response: AxiosResponse) => {
        setDefaults(response.data);
        setLoading(false);
        if (!projectId) {
          setloadingProject(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  let initialValues: any = {};
  if (project) {
    initialValues = {
      form: project.form ? project.form : "",
      term: project.term ? project.term : "",
      title: project.title ? project.title : "",
      year: project.year ? project.year : "",
      subject_id: project.subject_id ? project.subject_id : "",
    };
  } else {
    initialValues = {
      form: "",
      term: "",
      title: "",
      year: "",
      subject_id: "",
    };
  }

  const handleSubmit = (values: any) => {
    const request_body = values;

    if (projectId) {
      request_body.id = projectId;
      toast
        .promise(
          request.update(Endpoints.projects + "/" + projectId, request_body),
          {
            pending: "Please wait..",
            success: {
              render({ data }) {
                return "Successfully updated";
              },
            },
            error: {
              render({ data }) {
                return data;
              },
            },
          }
        )
        .then((response: AxiosResponse) => {
          history.push("/project-detail/" + response.data.id);
        })
        .catch((error: AxiosError) => {
          console.log(error);
        });
    } else {
      toast
        .promise(request.create(Endpoints.projects, request_body), {
          pending: "Please wait..",
          success: {
            render({ data }) {
              return "Successfully created";
            },
          },
          error: {
            render({ data }) {
              return data;
            },
          },
        })
        .then((response: AxiosResponse) => {
          history.push("/project-detail/" + response.data.id);
        })
        .catch((error: AxiosError) => {
          console.log(error);
        });
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>New Project | Treda</title>
        </MetaTags>

        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Projects" breadcrumbItem="New Project" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {(loading || loadingProject) && (
                    <Loading label="Loading Project. Please wait." />
                  )}
                  {!loading && !loadingProject && (
                    <>
                      <Formik
                        initialValues={initialValues}
                        enableReinitialize
                        validateOnBlur
                        onSubmit={(values: any) => handleSubmit(values)}
                      >
                        {({
                          isSubmitting,
                          setFieldValue,
                          values,
                          errors,
                          touched,
                        }) => (
                          <>
                            <Form>
                              <Row>
                                <Col lg={3}>
                                  <XSearchableSelect
                                    key="form"
                                    id="form"
                                    name={"form"}
                                    value={values.form}
                                    options={classes}
                                    label={"Class"}
                                    onChange={(value: any) => {
                                      setFieldValue("form", value.value);
                                    }}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={3}>
                                  <XSelect
                                    key="subject_id"
                                    id="subject_id"
                                    name={"subject_id"}
                                    options={subjects}
                                    label={"Subject"}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={3}>
                                  <XSelect
                                    key="term"
                                    id="term"
                                    name={"term"}
                                    options={getTerms()}
                                    label={"Term"}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={2}>
                                  <XTextBox
                                    type="text"
                                    key="year"
                                    id="year"
                                    name={"year"}
                                    label={"Year"}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <XTextArea
                                  id="title"
                                  label="Title"
                                  name="title"
                                  size={{ lg: 12 }}
                                  touched={touched}
                                />
                              </Row>

                              <div className="modal-footer">
                                <Button
                                  disabled={isSubmitting}
                                  type="submit"
                                  className="btn btn-primary"
                                >
                                  Save
                                </Button>
                              </div>
                            </Form>
                          </>
                        )}
                      </Formik>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default NewProject;
