import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Container,
  DropdownToggle,
  DropdownMenu, DropdownItem, UncontrolledDropdown, Button
} from "reactstrap";
import MetaTags from "react-meta-tags";

//Import Breadcrumb
import Breadcrumbs from "../../components/common/Breadcrumb";
import "../../../src/assets/scss/custom/plugins/_datatables.scss";
import { ApiCore } from "../../helpers/api_helper";
import { Loading } from "../../components/common/Loading";
import { AxiosResponse } from "axios";
import { Endpoints } from "../../utils/Endpoints";
import GridForm from "./grid";
import { XTextBox } from "../../components/common/inputs/XTextBox";
import { Form, Formik } from "formik";
import {toast, ToastContainer} from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";

const CreateMarks = () => {

  const [loading, setLoading] = useState<boolean>(false);
  const [grid, setGrid] = useState<any>([]);
  const [selectedMarkTypes, setSelectedMarkTypes] = useState<any>([]);

  const params = new URLSearchParams(useLocation().search);

  const [initialValues, setInitialValues] = useState<any>({
  });

  const [topic, setTopic] = useState<any>(null);

  const request = new ApiCore();

  useEffect(() => {
  if (params.get("topic_id")) {
      request
        .get(Endpoints.topics + "/" + params.get("topic_id"))
        .then((response: AxiosResponse) => {
          setTopic(response.data)
          setInitialValues({topic_id: params.get("topic_id"), form: response.data.form, subject: response.data.subject_id, term: response.data.term})
        })
        .catch(error => {
          console.error(error);
        });   
  }}, []);

  const loadMarks = (values: any, selectedTypes: any) => {
    setInitialValues(values);
    setSelectedMarkTypes(selectedTypes);
    const responseType = (values.excel && values.excel == 1) ? "arraybuffer" : "json";
    toast.promise(request.createFormData(Endpoints.marks, values, responseType), {
      pending: "Please wait..",
      success: {
        render({data}) {
          let message: any = "";
          if (values.file) {
            message = data?.data.message;
          } else if(values.excel && values.excel == 1) {
            message = "The excel file is ready."
          }
          return message;
        }
      },
      error: {
        render({data}) {
          return data;
        }
      }
    }).then((response: AxiosResponse) => {
      if (values.file) {
        //Nothing to do here
      } else if(values.excel && values.excel == 1) {
        const type = response.headers['content-type']
        const blob = new Blob([response.data], {type: type})
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `marks-${values.form}-${values.stream}-${values.year}-${values.term}.xlsx`
        link.click()
      } else {
        const data = response.data;
        updateGrid(data, selectedTypes, values);
      }
    });
  }

  const handleSubmit = (values: any) => {
    setLoading(true);
    setInitialValues(values);
    request
    .create(Endpoints.marks, values)
    .then((response: AxiosResponse) => {
      const data = response.data;
      console.log(data);
      toast.success(data);
      setLoading(false);
    })
    .catch(error => {
      setLoading(false)
      toast.error(error);
    });
  }

  const updateGrid = (data: any, types: any, values: any) => {
    let marks: any = {};
    console.log(data);
    console.log(types);
    console.log(values);

    data.forEach((element: any) => {
      types.forEach((type: any) => {
        if (marks[`mt_${type.id}`]) {
          
        } else {
          marks[`mt_${type.id}`] = {};
        }
        
        if (element.marktypes[type.id]) {
          marks[`mt_${type.id}`][`sid_${element.student.studentid}`] = element.marktypes[type.id].mark;
        }
      });
    });
    setInitialValues({...values, marks: marks});
    setGrid(data);
    setLoading(false);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Marks - Treda</title>
        </MetaTags>

        <Container fluid>
          <Breadcrumbs title="Marks" breadcrumbItem="List" />
          <Row>
            <Col md={12} lg={12} xl={12}>
              <Card>
                <CardHeader className="justify-content-between d-flex align-items-center">
                  <CardTitle className="h4">
                    Create Marks
                  </CardTitle>

                  <UncontrolledDropdown className="mt-4 mt-sm-0">
                    <DropdownToggle tag="a" className="btn btn-light">
                      <i className="fa fa-ellipsis-v"></i>
                    </DropdownToggle>

                    <DropdownMenu className="dropdown-menu-end">
                      <DropdownItem to="#">
                        Add Marks
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </CardHeader>
                <CardBody>
                  {loading && <Loading label="Loading marks. Please wait." />}
                  {!loading &&
                    <>
                      <Row className="mb-2">
                        <Col xl="12">
                          <GridForm
                            initialValues={initialValues}
                            handleSearch={(values: any, selectedTypes: any) => {
                              delete values.marks;
                              loadMarks(values, selectedTypes);
                            }}
                            topic={topic}
                          />
                          <ToastContainer />
                        </Col>
                      </Row>
                      {grid.length > 0 &&
                        <Formik
                          initialValues={initialValues}
                          enableReinitialize
                          validateOnBlur
                          onSubmit={(values: any) => handleSubmit(values)}
                        >
                          {({ isSubmitting, setFieldValue, values, errors, touched }) => (
                            <>
                              <Form>
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th>Name</th>
                                      {selectedMarkTypes.map((type: any) => {
                                        return <th key={type.id}>{type.label}</th>
                                      })}
                                    </tr>
                                  </thead>
                                  <tbody>
                                  {grid.map((item: any, index: number) => {
                                    return <tr key={index}>
                                      <td>{item.student.fullname}</td>
                                      {selectedMarkTypes.map((type: any) => {
                                        return <th key={type.id}>
                                          <XTextBox
                                            type="text"
                                            key={`marks.mt_${type.id.toString()}.sid_${item.student.studentid}`}
                                            id={`marks.mt_${type.id.toString()}.sid_${item.student.studentid}`}
                                            name={`marks[mt_${type.id.toString()}][sid_${item.student.studentid}]`}
                                            touched={touched}
                                          />
                                        </th>
                                      })}
                                    </tr>
                                  })}
                                  </tbody>
                                </table>
                                <div>                          
                                  <Button
                                    disabled={false}
                                    type="submit"
                                    name="btn-submit"
                                    className="btn btn-primary"
                                  >
                                    SAVE
                                  </Button>
                                </div>
                              </Form>
                            <hr/>
                          </>
                        )}
                      </Formik>
                      }
                    </>
                  }

                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>

      </div>
    </React.Fragment>
  );
};

export default CreateMarks;