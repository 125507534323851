import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Container,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Button,
} from "reactstrap";
import MetaTags from "react-meta-tags";

//Import Breadcrumb
import Breadcrumbs from "../../../components/common/Breadcrumb";
// import "../../../src/assets/scss/custom/plugins/_datatables.scss";
import { ApiCore } from "../../../helpers/api_helper";
import { Loading } from "../../../components/common/Loading";
import { AxiosResponse } from "axios";
import { Endpoints } from "../../../utils/Endpoints";
import GridForm from "./grid";
import { XTextBox } from "../../../components/common/inputs/XTextBox";
import { Form, Formik } from "formik";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import { XTextArea } from "../../../components/common/inputs/XTextArea";

const CreateMarkTargets = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [grid, setGrid] = useState<any>([]);
  const [selectedMarkTargets, setSelectedMarkTargets] = useState<any>([]);
  const [marktargets, setMarkTargets] = useState<any>({});
  const [students, setStudents] = useState<any>([]);

  const params = new URLSearchParams(useLocation().search);

  const [initialValues, setInitialValues] = useState<any>({});

  const request = new ApiCore();

  const loadMarkTargets = (values: any, selectedTypes: any) => {
    setInitialValues(values);
    setSelectedMarkTargets(selectedTypes);
    const responseType =
      values.excel && values.excel == 1 ? "arraybuffer" : "json";
    toast
      .promise(
        request.createFormData(Endpoints.marktargets, values, responseType),
        {
          pending: "Please wait..",
          success: {
            render({ data }) {
              let message: any = "";
              if (values.file) {
                message = data?.data.message;
              } else if (values.excel && values.excel == 1) {
                message = "The excel file is ready.";
              }
              return message;
            },
          },
          error: {
            render({ data }) {
              return data;
            },
          },
        }
      )
      .then((response: AxiosResponse) => {
        if (values.file) {
          //Nothing to do here
        } else if (values.excel && values.excel == 1) {
          const type = response.headers["content-type"];
          const blob = new Blob([response.data], { type: type });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `mustard-${+new Date()}.xlsx`;
          link.click();
        } else {
          const data = response.data;
          setMarkTargets(response.data.targets);
          setStudents(response.data);
          updateGrid(data, values);
        }
      });
  };

  const handleSubmit = (values: any) => {
    console.log(values);
    setLoading(true);
    setInitialValues(values);
    request
      .create(Endpoints.marktargets, values)
      .then((response: AxiosResponse) => {
        const data = response.data;
        console.log(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const updateGrid = (data: any, values: any) => {
    let target: any = {};
    console.log(data);
    console.log(values);

    console.log(target);

    data.forEach((element: any) => {

      if (target[`sid_${element.student.id.toString()}`]) {
      } else {
        target[`sid_${element.student.id.toString()}`] = "";
      }

      if (element.targets.target) {
        target[`sid_${element.student.id.toString()}`] = element.targets.target;
      }

    });

    setInitialValues({ ...values, target: target });
    setLoading(false);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Mark Targets - Treda</title>
        </MetaTags>

        <Container fluid>
          <Breadcrumbs title="Mark Targets" breadcrumbItem="List" />
          <Row>
            <Col md={12} lg={12} xl={12}>
              <Card>
                <CardHeader className="justify-content-between d-flex align-items-center">
                  <CardTitle className="h4">Create Mark Targets</CardTitle>

                  <UncontrolledDropdown className="mt-4 mt-sm-0">
                    <DropdownToggle tag="a" className="btn btn-light">
                      <i className="fa fa-ellipsis-v"></i>
                    </DropdownToggle>

                    <DropdownMenu className="dropdown-menu-end">
                      <DropdownItem to="#">Add Marks</DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </CardHeader>
                <CardBody>
                  {loading && (
                    <Loading label="Loading mark targets. Please wait." />
                  )}
                  {!loading && (
                    <>
                      <Row className="mb-2">
                        <Col xl="12">
                          <GridForm
                            initialValues={initialValues}
                            handleSearch={(values: any, selectedTypes: any) => {
                              delete values.target;
                              loadMarkTargets(values, selectedTypes);
                            }}
                          />
                          <ToastContainer />
                        </Col>
                      </Row>
                      {students.length > 0 && (
                        <Formik
                          initialValues={initialValues}
                          enableReinitialize
                          validateOnBlur
                          onSubmit={(values: any) => handleSubmit(values)}
                        >
                          {({
                            isSubmitting,
                            setFieldValue,
                            values,
                            errors,
                            touched,
                          }) => (
                            <>
                              <Form>
                                <Row>
                                  <Col sm={3}>Student ID</Col>
                                  <Col sm={4}>
                                    Name
                                  </Col>
                                  <Col>
                                    Target
                                  </Col>
                                </Row>
                                <hr/>
                                {students.map((item: any, index: number) => {
                                  return (
                                    <>
                                      <Row>
                                        <Col sm={3}>{item.student.studentid}</Col>
                                        <Col sm={4}>
                                          {item.student.fullname}
                                        </Col>
                                        <Col>
                                          <XTextBox
                                            type="text"
                                            key={`target.sid_${item.student.id.toString()}`}
                                            id={`target.sid_${item.student.id.toString()}`}
                                            name={`target.sid_${item.student.id.toString()}`}
                                            touched={touched}
                                          />
                                        </Col>
                                      </Row>
                                    </>
                                  );
                                })}
                                <div>
                                  <Button
                                    disabled={false}
                                    type="submit"
                                    name="btn-submit"
                                    className="btn btn-primary"
                                  >
                                    Create MarkTarget
                                  </Button>
                                </div>
                              </Form>
                              <hr />
                            </>
                          )}
                        </Formik>
                      )}
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CreateMarkTargets;
