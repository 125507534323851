import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Container,
  DropdownToggle,
  DropdownMenu, DropdownItem, UncontrolledDropdown, Button
} from "reactstrap";
import MetaTags from "react-meta-tags";

//Import Breadcrumb
import Breadcrumbs from "../../components/common/Breadcrumb";
import "../../../src/assets/scss/custom/plugins/_datatables.scss";
import { ApiCore } from "../../helpers/api_helper";
import { Loading } from "../../components/common/Loading";
import { AxiosResponse } from "axios";
import { Endpoints } from "../../utils/Endpoints";
import GridForm from "./grid";
import { XTextBox } from "../../components/common/inputs/XTextBox";
import { Form, Formik } from "formik";
import {toast, ToastContainer} from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { XSelect } from "../../components/common/inputs/XSelect";
import { getForms } from "../../utils/Helpers";
import { XCheckbox } from "../../components/common/inputs/XCheckbox";

const Marksheet = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<any>({
    excel: 1
  });

  const [data, setData] = useState<any>(null);
  const ref = React.useRef<HTMLIFrameElement>(null);

  const request = new ApiCore();

  const terms = [
    {value: 'I', text: 'I'},
    {value: 'II', text: 'II'},
    {value: 'III', text: 'III'}
  ]

  const categories = [
    {value: 'assesment', text: 'Assessment'},
    {value: 'exam', text: 'Exam'}
  ]


  const handleSubmit = (values: any) => {
    if (!values.html || values.html != 1) {
      delete values.html;
    }

    if (!values.excel || values.excel != 1) {
      delete values.excel;
    }

    if (!values.pdf || values.pdf != 1) {
      delete values.pdf;
    }

    toast.promise(request.get(Endpoints.markSheet, {params: values}, values.html ? "json" : "arraybuffer"), {
      pending: "Please wait..",
      success: {
        render({data}) {
          let message: any = "";
          if(values.excel && values.excel == 1) {
            message = "The marksheet is ready."
          }
          return message;
        }
      },
      error: {
        render({data}) {
          return data;
        }
      }
    }).then((response: AxiosResponse) => {
      if (values.html) {
        setData(response.data);
      } else if((values.excel && values.excel == 1) || (values.pdf && values.pdf == 1) ) {
        const fileExt = values.excel ? "xlsx" : "pdf";
        const type = response.headers['content-type']
        const blob = new Blob([response.data], {type: type})
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `marksheet-${values.form}-${values.stream}-${values.year}-${values.term}.${fileExt}`
        link.click()
      }
    });
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Mark sheet - Treda</title>
        </MetaTags>

        <Container fluid>
          <Breadcrumbs title="Marks" breadcrumbItem="List" />
          <Row>
            <Col md={12} lg={12} xl={12}>
              <Card>
                <CardHeader className="justify-content-between d-flex align-items-center">
                  <CardTitle className="h4">
                    Marksheet
                  </CardTitle>

                  <UncontrolledDropdown className="mt-4 mt-sm-0">
                    <DropdownToggle tag="a" className="btn btn-light">
                      <i className="fa fa-ellipsis-v"></i>
                    </DropdownToggle>

                    <DropdownMenu className="dropdown-menu-end">
                      <DropdownItem to="/marks/create">
                        Add Marks
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </CardHeader>
                <CardBody>
                  {loading && <Loading label="Loading marks. Please wait." />}
                  {!loading &&
                    <>
                      <Row className="mb-2">
                        <Col xl="12">
                          <ToastContainer />
                        </Col>
                      </Row>
                        <Formik
                          initialValues={initialValues}
                          enableReinitialize
                          validateOnBlur
                          onSubmit={(values: any) => handleSubmit(values)}
                        >
                          {({ isSubmitting, setFieldValue, values, errors, touched }) => (
                  <>
                    <Form>
                      <Row>
                        <Col lg={2}>
                          <XSelect
                            key="form"
                            id="form"
                            name={"form"}
                            options={getForms()}
                            label={"Class"}
                            touched={touched}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={2}>
                          <XTextBox
                            type="text"
                            key="stream"
                            id="stream"
                            name={"stream"}
                            label={"Stream"}
                            touched={touched}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={2}>
                          <XSelect
                            key="studentcategory_id"
                            id="studentcategory_id"
                            name={"studentcategory_id"}
                            dataSrc={Endpoints.schoolStudentCategories}
                            dataType={"studentcategories"}
                            label={"Student Category"}
                            touched={touched}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={1}>
                          <XSelect
                            key="term"
                            id="term"
                            name={"term"}
                            options={terms}
                            label={"Term"}
                            touched={touched}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={1}>
                          <XTextBox
                            type="text"
                            key="year"
                            id="year"
                            name={"year"}
                            label={"Year"}
                            touched={touched}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={2}>
                          <XSelect
                            key="category"
                            id="category"
                            name={"category"}
                            options={categories}
                            label={"Category"}
                            touched={touched}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={2}>
                          <XSelect
                            key="marktype_id"
                            id="marktype_id"
                            name={"marktype_id"}
                            dataSrc={Endpoints.marktypes}
                            dataType={"marktypes"}
                            labelField={"label"}
                            label={"Mark Type"}
                            touched={touched}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={1}>
                          <XCheckbox
                            key="excel"
                            id="excel"
                            name={"excel"}
                            label={"Excel"}
                            value="1"
                            includeTopPadding={true}
                            onChange={setFieldValue}
                            checked={values.excel == 1}
                            size={{}}
                          />
                        </Col>      
                        <Col lg={1}>
                          <XCheckbox
                            key="pdf"
                            id="pdf"
                            name={"pdf"}
                            label={"PDF"}
                            value="1"
                            includeTopPadding={true}
                            onChange={setFieldValue}
                            checked={values.pdf == 1}
                            size={{}}
                          />
                        </Col>   
                        <Col lg={2}>
                          <XCheckbox
                            key="html"
                            id="html"
                            name={"html"}
                            label={"Preview"}
                            value="1"
                            includeTopPadding={true}
                            onChange={setFieldValue}
                            checked={values.html == 1}
                            size={{}}
                          />
                        </Col>               
                        <Col lg={1}>
                          <div>&nbsp;</div>
                          <Button
                            disabled={false}
                            type="submit"
                            className="btn btn-primary"
                          >
                            Next
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                    <hr/>
                  </>
                )}
                      </Formik>
                    </>
                  }

                </CardBody>
              </Card>
            </Col>
          </Row>
          {data && (
          <Row>
            <Col md={12} lg={12} xl={12}>
              <Card>
                <CardBody>
                <iframe ref={ref} srcDoc={data} width="100%" height="800px"  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          )}
        </Container>

      </div>
    </React.Fragment>
  );
};

export default Marksheet;