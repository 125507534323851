import React, { useEffect, useState } from "react";

//import Breadcrumbs
import { Loading } from "../../../components/common/Loading";

import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";

import { FieldArray, Form, Formik } from "formik";
import { XSelect } from "../../../components/common/inputs/XSelect";
import { Endpoints } from "../../../utils/Endpoints";
import { ApiCore } from "../../../helpers/api_helper";
import { AxiosError, AxiosResponse } from "axios";
import { getForms } from "../../../utils/Helpers";
import { XTextBox } from "../../../components/common/inputs/XTextBox";
import { XCheckbox } from "../../../components/common/inputs/XCheckbox";
import { XFileInput } from "../../../components/common/inputs/XFileInput";

const GridForm = (props: any) => {
  const [studentcategories, setStudentcategories] = useState<any>([]);
  const [projectscores, setProjectScores] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingProjectScores, setLoadingProjectScores] =
    useState<boolean>(true);

  const request = new ApiCore();

  useEffect(() => {
    request
      .get(Endpoints.schoolStudentCategories, {})
      .then((response: AxiosResponse) => {
        const { studentcategories, totalRecords } = response.data;
        let options: any = [];
        options = studentcategories.map((data: any) => ({
          value: data.id,
          text: data.name || data.taxid,
          data: JSON.stringify(data),
        }));
        setStudentcategories(options);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    request
      .get(Endpoints.projectScores, {})
      .then((response: AxiosResponse) => {
        const { projectscores } = response.data;
        setProjectScores(projectscores);
        setLoadingProjectScores(false);
        setLoading(false);
        console.log(projectscores);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const terms = [
    { value: "I", text: "I" },
    { value: "II", text: "II" },
    { value: "III", text: "III" },
  ];

  const papers = [
    { value: "I", text: "I" },
    { value: "II", text: "II" },
    { value: "III", text: "III" },
    { value: "IV", text: "IV" },
    { value: "V", text: "V" },
    { value: "VI", text: "VI" },
  ];

  const handleSubmit = (values: any) => {
    let selectedProjectScores = projectscores;
    if (values.projectscores && values.projectscores.length > 0) {
      selectedProjectScores = [];
      projectscores.forEach((item: any) => {
        if (values.projectscores.includes(item.id)) {
          selectedProjectScores.push(item);
        }
      });
    }
    props.handleSearch(values, selectedProjectScores);
  };

  return (
    <React.Fragment>
      <div>
        <Container fluid>
          {loading ||
            (loadingProjectScores && (
              <Loading label="Loading projectscores. Please wait." />
            ))}
          {!loading && !loadingProjectScores && (
            <>
              <Formik
                initialValues={props.initialValues}
                enableReinitialize
                validateOnBlur
                onSubmit={(values: any) => handleSubmit(values)}
              >
                {({ isSubmitting, setFieldValue, values, errors, touched }) => (
                  <>
                    <Form>
                      <Row>
                        <Col lg={2}>
                          <XSelect
                            key="form"
                            id="form"
                            name={"form"}
                            options={getForms()}
                            label={"Class"}
                            touched={touched}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={2}>
                          <XTextBox
                            type="text"
                            key="stream"
                            id="stream"
                            name={"stream"}
                            label={"Stream"}
                            touched={touched}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={2}>
                          <XSelect
                            key="subject"
                            id="subject"
                            name={"subject_id"}
                            dataSrc={Endpoints.subjects}
                            dataType={"subjects"}
                            label={"Subject"}
                            touched={touched}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={2}>
                          <XSelect
                            key="term"
                            id="term"
                            name={"term"}
                            options={terms}
                            label={"Term"}
                            touched={touched}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={2}>
                          <XTextBox
                            type="text"
                            key="year"
                            id="year"
                            name={"year"}
                            label={"Year"}
                            touched={touched}
                            errors={errors}
                          />
                        </Col>

                        <Col lg={2}>
                          <div>&nbsp;</div>
                          <Button
                            disabled={false}
                            type="submit"
                            className="btn btn-primary"
                          >
                            Next
                          </Button>
                        </Col>
                      </Row>
                      {props.project ? (
                        <div>
                          <hr />
                          {props.project.title}
                        </div>
                      ) : (
                        <></>
                      )}
                    </Form>
                    <hr />
                  </>
                )}
              </Formik>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default GridForm;
