import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Container,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";
import MetaTags from "react-meta-tags";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import Breadcrumbs from "../../components/common/Breadcrumb";
import "../../../src/assets/scss/custom/plugins/_datatables.scss";
import { ApiCore } from "../../helpers/api_helper";
import { Loading } from "../../components/common/Loading";
import { AxiosResponse } from "axios";
import XModal from "../../components/common/XModal";
import CreateForm from "../../components/forms/CreateForm";
import { Endpoints } from "../../utils/Endpoints";
import { ClassteacherFields } from "../../fields/ClassteacherFields";
import SearchMarks from "./search";
import BulkUpload from "./upload";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { can } from "../../utils/Helpers";

const Deputies = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [deputies, setDeputies] = useState<any>([]);
  const [showDeputyHeadteacherDialog, setShowDeputyHeadteacherDialog] =
    useState<boolean>(false);
  const [showDeputyHeadteacherBulkDialog, setShowDeputyHeadteacherBulkDialog] =
    useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<any>({
    firstname: "",
    lastname: "",
  });

  const columns = [
    {
      dataField: "form",
      text: "Class",
      formatter: (cellContent: any, item: any) => <span>{item.form}</span>,
    },
    {
      dataField: "stream",
      text: "Stream",
    },
    {
      dataField: "user.name",
      text: "Teacher",
    },
    {
      dataField: "",
      text: "",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, product: any) => (
        <UncontrolledDropdown>
          <DropdownToggle className="card-drop" tag="a">
            <i className="mdi mdi-dots-horizontal font-size-18 text-muted"></i>
          </DropdownToggle>
          {can("manage", "School::Deputy") ? (
            <>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem
                  to="#"
                  onClick={() => handleOption(product, "edit")}
                >
                  <i className="mdi mdi-pencil font-size-16 text-success me-1"></i>{" "}
                  Edit
                </DropdownItem>
              </DropdownMenu>
            </>
          ) : (
            <></>
          )}
        </UncontrolledDropdown>
      ),
    },
  ];

  const handleOption = (item: any, type: string) => {
    if (type == "edit") {
      setInitialValues(item);
      setShowDeputyHeadteacherDialog(true);
    } else if (type == "new") {
      setInitialValues({
        form: "",
        stream: "",
        user_id: "",
      });
      setShowDeputyHeadteacherDialog(true);
    }
  };

  const defaultSorted: any = [
    {
      dataField: "name",
      order: "asc",
    },
  ];

  const pageOptions: any = {
    sizePerPage: 100,
    totalSize: deputies.length,
    custom: true,
  };

  const selectRow: any = {
    mode: "checkbox",
  };

  const request = new ApiCore();

  useEffect(() => {
    searchDeputies({});
  }, []);

  const handleUpdate = (item: any) => {
    const items = deputies.map((i: any) => {
      if (i.id === item.id) {
        return { ...i, ...item };
      }

      return i;
    });
    deputies(items);
  };

  const searchDeputies = (values: any) => {
    toast
      .promise(
        request.get(
          Endpoints.deputies,
          { params: values },
          values.excel && values.excel == 1 ? "arraybuffer" : "json"
        ),
        {
          pending: "Please wait..",
          success: {
            render({ data }) {
              let message: any = "";
              return message;
            },
          },
          error: {
            render({ data }) {
              return data;
            },
          },
        }
      )
      .then((response: AxiosResponse) => {
        if (values.excel && values.excel == 1) {
          const type = response.headers["content-type"];
          const blob = new Blob([response.data], { type: type });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `mustard-${+new Date()}.xlsx`;
          link.click();
        } else {
          const { deputies, totalRecords } = response.data;
          setDeputies(deputies);
          setLoading(false);
        }
      });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Deputy Head Teachers - Treda</title>
        </MetaTags>

        <Container fluid>
          <Breadcrumbs title="DeputyHeadTeachers" breadcrumbItem="List" />

          <XModal
            title={"Add deputy head teacher"}
            width={"lg"}
            open={showDeputyHeadteacherDialog}
            toggle={() =>
              setShowDeputyHeadteacherDialog(!showDeputyHeadteacherDialog)
            }
            onClose={() => setShowDeputyHeadteacherDialog(false)}
          >
            <CreateForm
              fields={ClassteacherFields}
              initialValues={initialValues}
              submitEndpoint={
                initialValues.id
                  ? Endpoints.deputies + "/" + initialValues.id
                  : Endpoints.deputies
              }
              submitButtonLabel={initialValues.id ? "Update" : "Save"}
              debug={false}
              handleUpdate={handleUpdate}
              onClose={() => setShowDeputyHeadteacherDialog(false)}
            />
          </XModal>

          <Row>
            <Col md={12} lg={12} xl={12}>
              <Card>
                <CardHeader className="justify-content-between d-flex align-items-center">
                  <CardTitle className="h4">Deputy Head Teachers</CardTitle>

                  <UncontrolledDropdown className="mt-4 mt-sm-0">
                    <DropdownToggle tag="a" className="btn btn-light">
                      <i className="fa fa-ellipsis-v"></i>
                    </DropdownToggle>

                    <DropdownMenu className="dropdown-menu-end">
                      {can("manage", "School::Deputy") && (
                        <>
                          <DropdownItem
                            to="#"
                            onClick={() => handleOption(null, "new")}
                          >
                            New Depty
                          </DropdownItem>
                        </>
                      )}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </CardHeader>
                <CardBody>
                  {loading && (
                    <Loading label="Loading deputies. Please wait." />
                  )}
                  {!loading && (
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={columns}
                          data={deputies}
                          search
                          bootstrap4
                        >
                          {(toolkitProps) => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col xl="12">
                                  <SearchMarks
                                    handleSearch={(values: any) => {
                                      searchDeputies(values);
                                    }}
                                  />
                                </Col>
                                <ToastContainer />
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle table-nowrap table-hover"
                                      }
                                      bordered={false}
                                      striped={true}
                                    />
                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Deputies;
