import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Container } from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { ApiCore } from "../../helpers/api_helper";
import { AxiosResponse } from "axios";
import { Endpoints } from "../../utils/Endpoints";

//Import React Router
import { useParams } from "react-router-dom";
import { fullUrl } from "../../utils/Helpers";

const request = new ApiCore();

const StudentDetails = () => {
  const { id } = useParams();
  const [student, setStudent] = useState<any>(null);

  useEffect(() => {
    loadStudent();
  }, []);

  const loadStudent = () => {
    request
      .get(`${Endpoints.students}/${id}`, {})
      .then((response: AxiosResponse) => {
        const { data } = response;
        setStudent(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  
  return (
    <React.Fragment>
      {student && (
        <>
          <div className="page-content">
            <ToastContainer />
            <Container fluid>
              <Row>
                <Col md={12} lg={12} xl={12}>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col md={2}>
                          <img
                            src={fullUrl(student.photo_path)}
                            alt="logo"
                            height="28"
                          />
                        </Col>
                        <Col md={10}>
                          <Row>
                            <Col md={3}>
                              <div className="dt-label">Name</div>
                              <div className="dt-value">{student.firstname} {student.lastname}</div>
                            </Col>
                            <Col md={3}>
                              <div className="dt-label">Phone</div>
                              <div className="dt-value">{student.phone}</div>
                            </Col>
                            <Col md={3}>
                              <div className="dt-label">Address</div>
                              <div className="dt-value">{student.address}</div>
                            </Col>
                            <Col md={3}>
                              <div className="dt-label">Class</div>
                              <div className="dt-value">{student.form} {student.stream}</div>
                            </Col>
                          </Row>
                          <hr/>
                          <Row>
                            <Col md={3}>
                              <div className="dt-label">Email</div>
                              <div className="dt-value">{student.email}</div>
                            </Col>
                            <Col md={3}>
                              <div className="dt-label">Payment Code</div>
                              <div className="dt-value">{student.paymentcode}</div>
                            </Col>
                            <Col md={3}>
                              <div className="dt-label">Gender</div>
                              <div className="dt-value">{student.gender}</div>
                            </Col>
                          </Row>
                          <hr/>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </>
      )}
    </React.Fragment>
  );
};

export default StudentDetails;
