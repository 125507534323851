import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Container,
  DropdownToggle,
  DropdownMenu, DropdownItem, UncontrolledDropdown, Button
} from "reactstrap";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import Breadcrumbs from "../../components/common/Breadcrumb";
import "../../../src/assets/scss/custom/plugins/_datatables.scss";
import { ApiCore } from "../../helpers/api_helper";
import { Loading } from "../../components/common/Loading";
import { AxiosResponse } from "axios";
import XModal from "../../components/common/XModal";
import CreateForm from "../../components/forms/CreateForm";
import { Endpoints } from "../../utils/Endpoints";
import { AutoreportcommentFields } from "../../fields/AutoreportcommentFields";
import { Form, Formik } from "formik";
import { XSelect } from "../../components/common/inputs/XSelect";
import { getForms } from "../../utils/Helpers";

const Autoreportcomments = () => {

  const [loading, setLoading] = useState<boolean>(false);
  const [autoreportcomments, setAutoreportcomments] = useState<any>([]);
  const [showAutoreportcommentDialog, setShowAutoreportcommentDialog] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<any>({});

  const columns = [
    {
      dataField: "remarktype.lb",
      text: "Remark type",
    },{
      dataField: "score",
      text: "From"
    },{
      dataField: "upper",
      text: "To"
    },
    {
      dataField: "comment",
      text: "Comment"
    },
    {
      dataField: "form",
      text: "Class"
    },
    {
      dataField: "",
      text: "",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, product: any) => <UncontrolledDropdown>
        <DropdownToggle className="card-drop" tag="a">
          <i className="mdi mdi-dots-horizontal font-size-18 text-muted"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem to="#" onClick={() => handleOption(product, "edit")}>
            <i className="mdi mdi-pencil font-size-16 text-success me-1"></i> Edit
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    }
  ];


  const handleOption = (item: any, type: string) => {
    if (type == "edit") {
      setInitialValues(item);
      setShowAutoreportcommentDialog(true);
    } else if (type == "new") {
      setInitialValues({});
      setShowAutoreportcommentDialog(true);
    }
  }  

  const defaultSorted: any = [
    {
      dataField: "name",
      order: "asc"
    }
  ];

  const pageOptions: any = {
    sizePerPage: 50,
    totalSize: autoreportcomments.length,
    custom: true
  };

  const selectRow: any = {
    mode: "checkbox"
  };

  const request = new ApiCore();
  const { SearchBar } = Search;


  const handleUpdate = (item: any) => {
    let found = false;
    const items = autoreportcomments.map((i: any) => {
      if (i.id === item.id){
        found = true;
        return {...i, ...item};
      }

      return i;
    })
    if (!found) {
      items.push(item);
    }
    setAutoreportcomments(items);
  };

  const handleSearch = (values: any) => {
    setLoading(true);
    request.get(Endpoints.autoreportcomments, { params: values })
      .then((response: AxiosResponse) => {
        const { autoreportcomments, totalRecords } = response.data;
        setAutoreportcomments(autoreportcomments);
        setLoading(false);

      })
      .catch((error) => {
        console.error(error);
      });   
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Auto Report Comment - Treda</title>
        </MetaTags>

        <Container fluid>
          <Breadcrumbs title="Auto Comment" breadcrumbItem="List" />

          <XModal title={"Add a comment"}
                  width={"lg"}
                  open={showAutoreportcommentDialog}
                  toggle={() => setShowAutoreportcommentDialog(!showAutoreportcommentDialog)}
                  onClose={() => setShowAutoreportcommentDialog(false)}>

            <CreateForm
              fields={AutoreportcommentFields}
              initialValues={initialValues}
              submitEndpoint={initialValues.id ? Endpoints.autoreportcomments + "/" + initialValues.id : Endpoints.autoreportcomments}
              submitButtonLabel={initialValues.id ? "Edit Comment" : "Add Comment"}
              debug={false}
              handleUpdate={handleUpdate}
              onClose={() => setShowAutoreportcommentDialog(false)} />

          </XModal>

          <Row>
            <Col md={12} lg={12} xl={12}>
              <Card>
                <CardHeader className="justify-content-between d-flex align-items-center">
                  <CardTitle className="h4">
                    Auto report comments
                  </CardTitle>

                  <UncontrolledDropdown className="mt-4 mt-sm-0">
                    <DropdownToggle tag="a" className="btn btn-light">
                      <i className="fa fa-ellipsis-v"></i>
                    </DropdownToggle>

                    <DropdownMenu className="dropdown-menu-end">
                      <DropdownItem to="#" onClick={() => handleOption(null, "new")}>
                        Add Comment
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </CardHeader>
                <CardBody>
                      <Formik
                        initialValues={initialValues}
                        enableReinitialize
                        validateOnBlur
                        onSubmit={(values: any) => handleSearch(values)}
                      >
                        {({
                          isSubmitting,
                          setFieldValue,
                          values,
                          errors,
                          touched,
                        }) => (
                          <>
                            <Form>
                              <Row>
                                <Col lg={2}>
                                  <XSelect
                                    key="form"
                                    id="form"
                                    name={"form"}
                                    options={getForms()}
                                    label={"Class"}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={2}>
                                  <XSelect
                                    key="remarktype_id"
                                    id="remarktype_id"
                                    name={"remarktype_id"}
                                    dataSrc={Endpoints.remarktypes}
                                    labelField={"lb"}
                                    dataType={"remarktypes"}
                                    label={"Remark Type"}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={2}>
                                  <div>&nbsp;</div>
                                  <Button
                                    disabled={false}
                                    type="submit"
                                    onClick={(e: any) => {
                                      handleSearch(values);
                                      e.preventDefault();
                                      return false;
                                    }}
                                    className="btn btn-primary"
                                  >
                                    Search
                                  </Button>
                                </Col>
                              </Row>
                            </Form>
                          </>
                        )}
                      </Formik>
                </CardBody>
                <CardBody>
                  {loading && <Loading label="Loading comments. Please wait." />}
                  {!loading &&
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={columns}
                          data={autoreportcomments}
                          search
                          bootstrap4
                        >
                          {toolkitProps => (
                            <React.Fragment>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                  <BootstrapTable
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    defaultSorted={defaultSorted}
                                    classes={
                                      "table align-middle table-nowrap table-hover"
                                    }
                                    bordered={false}
                                    striped={true}
                                  />
                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  }

                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>

      </div>
    </React.Fragment>
  );
};

export default Autoreportcomments;