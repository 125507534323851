import React from "react";
import ReactApexChart from "react-apexcharts";

const UserChart = (props: any) => {
    //console.log(props);
    const series = [
        {
            name: "# of students",
            data: props.data.yData.reverse()
        }
    ];
    var options = {
        chart: {
            id: "area-datetime",
            dropShadow: {
                enabled: true,
                color: "#000",
                top: 18,
                left: 7,
                blur: 4,
                opacity: 0.075
            },
            zoom: {
                autoScaleYaxis: true
            },
            toolbar: {
                show: false,
            }
        },

        dataLabels: {
            enabled: false
        },
        markers: {
            size: 3,
            strokeWidth: 3,

            hover: {
                size: 6,
            }
        },
        stroke: {
            show: true,
            // curve: "smooth",
            width: 3,
            dashArray: 0,
        },
        xaxis: {
            categories: props.data.xData.reverse(),
        },
        yaxis: {
            labels: {
                show: true,
            }
        },
        grid: {
            show: true,
            xaxis: {
                lines: {
                    show: true,
                }
            },
            yaxis: {
                lines: {
                    show: true,
                }
            }
        },
        colors: ["#038edc"],
        fill: {
            opacity: 0.05,
            colors: ["#038edc"],
            type: "solid",
        },
    };

    return (
        <React.Fragment>
            <ReactApexChart options={options} series={series} type="bar" height={440} className="apex-charts" />
        </React.Fragment>
    );
};

export default UserChart;