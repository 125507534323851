import { XTextBox } from "../../components/common/inputs/XTextBox";
import "react-toastify/dist/ReactToastify.css";
import { XSelect } from "../../components/common/inputs/XSelect";

const Newcurriculum = (props: any) => {
  const { values, data, student, remarks } = props;


  const getDescriptor = (score: number) => {
    let res = "Basic";
		if(score >= 2.5 && score <= 3.0){
		  res = "Outstanding";
    } else if (score >= 1.5 && score <= 2.49) {
		  res = "Moderate";
    }
    return res;
  };

  const records = data.records[student.id];
  return (
    <>
      {records && (
        <>
          <>
            <div>
              <strong>{student.fullname}</strong>&nbsp;&nbsp;&nbsp;
              <strong>{student.studentid}</strong>
            </div>
            <hr />
            <table className="table">
              <tbody>
                {Object.keys(records).map((subjectId: any) => {
                  const marks = records[subjectId];
                  let cleanRecords: any[] = [];
                  Object.keys(marks.mark).map((k: string) => {
                    if (k.length > 0) {
                      cleanRecords.push(marks.mark[k]);
                    }
                  });
                  return (
                    <>
                      <tr key={subjectId}>
                        <td colSpan={4}>
                          <b>{data.subjects[subjectId].name}</b>
                        </td>
                      </tr>
                      {cleanRecords.map((r: any, index: number) => {
                        if (index == 0) {
                          return (
                            <tr>
                              <td>{r.title}</td>
                              <td>{r.total}</td>
                              <td>{getDescriptor(r.total)}</td>
                              <td rowSpan={cleanRecords.length}></td>
                            </tr>
                          );
                        } else {
                          return (
                            <tr>
                              <td>{r.title}</td>
                              <td>{r.total}</td>
                              <td>{getDescriptor(r.total)}</td>
                            </tr>
                          );
                        }
                      })}
                    </>
                  );
                })}
              </tbody>
            </table>
            {remarks &&
              data.remarktypes.map((type: any) => {
                if (remarks[type.id]) {
                  return (
                    <>
                      <div>
                        <b>{type.label}</b>
                      </div>
                      <p>{remarks[type.id].remark}</p>
                    </>
                  );
                }
              })}
            <hr />
          </>
        </>
      )}
    </>
  );
};

export default Newcurriculum;
