import { XTextBox } from "../../components/common/inputs/XTextBox";
import "react-toastify/dist/ReactToastify.css";
import { XSelect } from "../../components/common/inputs/XSelect";

const PrimaryReport = (props: any) => {
  const { values, data, student, remarks, showRemarks = true } = props;

  const renderGeneralRow = (
    studentId: any,
    marks: any,
    subjectId: any,
    subjectName: any
  ) => {
    const paper = "I";
    return (
      <>
        <tr key={subjectId}>
          <td>{subjectName}</td>
          {data.marktypes.map((mt: any, index: number) => {
            return (
              <td key={`sid${studentId}_${mt.id}`}>
                {values.rawmark && values.rawmark == 1
                  ? marks.mark[paper][mt.id].raw
                  : marks.mark[paper][mt.id].computed}
              </td>
            );
          })}
          <td>{marks.mark[paper].total}</td>
          <td>{marks.mark[paper].grade}</td>
          <td>{marks.comment}</td>
        </tr>
      </>
    );
  };

  const renderAlevelRow = (
    studentId: any,
    marks: any,
    subjectId: any,
    subjectName: any
  ) => {
    const papers = Object.keys(marks.mark);
    return (
      <>
        {papers.map((paper: any, cnt: number) => {
          if (cnt == 0) {
            return (
              <tr key={subjectId}>
                <td rowSpan={papers.length}>{subjectName}</td>
                <td>{paper}</td>
                {data.marktypes.map((mt: any, index: number) => {
                  return (
                    <td key={`sid${studentId}_${mt.id}`}>
                      {values.rawmark && values.rawmark == 1
                        ? marks.mark[paper][mt.id].raw
                        : marks.mark[paper][mt.id].computed}
                    </td>
                  );
                })}
                <td>{marks.mark[paper].grade}</td>
                <td rowSpan={papers.length}>{marks.score}</td>
                <td rowSpan={papers.length}>{marks.comment}</td>
              </tr>
            );
          } else {
            return (
              <tr key={subjectId}>
                <td>{paper}</td>
                {data.marktypes.map((mt: any, index: number) => {
                  return (
                    <td key={`sid${studentId}_${mt.id}`}>
                      {values.rawmark && values.rawmark == 1
                        ? marks.mark[paper][mt.id].raw
                        : marks.mark[paper][mt.id].computed}
                    </td>
                  );
                })}
                <td>{marks.mark[paper].grade}</td>
              </tr>
            );
          }
        })}
      </>
    );
  };
  const records = data.records[student.id];

  return (
    <>
      <div>
        <strong>{student.fullname}</strong>&nbsp;&nbsp;&nbsp;
        <strong>{student.studentid}</strong>
      </div>
      <hr />
      {records && (
        <>
          <>
            <table className="table">
              <thead>
                {["S5", "S6"].includes(values.form) ? (
                  <>
                    <tr>
                      <th>SUBJECT</th>
                      <th>PAPER</th>
                      {data.marktypes.map((mt: any, index: number) => {
                        return <th key={index}>{mt.label}</th>;
                      })}
                      <th>GRADE</th>
                      <th>SCORE</th>
                      <th>COMMENT</th>
                    </tr>
                  </>
                ) : (
                  <>
                    <tr>
                      <th>Subject</th>
                      {data.marktypes.map((mt: any, index: number) => {
                        return <th key={index}>{mt.label}</th>;
                      })}
                      <th>TOTAL</th>
                      <th>GRADE</th>
                      <th>Comment</th>
                    </tr>
                  </>
                )}
              </thead>
              <tbody>
                {Object.keys(records).map((subjectId: any) => {
                  const marks = records[subjectId];
                  try {
                    return (
                      <>
                        {["S5", "S6"].includes(values.form)
                          ? renderAlevelRow(
                              student.id,
                              marks,
                              subjectId,
                              data.subjects[subjectId].name
                            )
                          : renderGeneralRow(
                              student.id,
                              marks,
                              subjectId,
                              data.subjects[subjectId].name
                            )}
                      </>
                    );
                  } catch (e) {}
                })}
              </tbody>
            </table>
            <hr />
            <div className="txt-center">
              {["S5", "S6"].includes(values.form) ? (
                <>
                  <b>TOTAL SCORE:</b> {data.aggregate[student.id]} &nbsp;&nbsp;
                </>
              ) : (
                <>
                  <b>AGG:</b> {data.aggregate[student.id]} &nbsp;&nbsp;
                  <b>DIV:</b> {data.division[student.id]}
                  &nbsp;&nbsp;<b>AVG:</b> {data.average[student.id]}
                  &nbsp;&nbsp;<b>TOTAL:</b> {data.total[student.id]}
                </>
              )}
            </div>
            <hr />
            {remarks && showRemarks &&
              data.remarktypes.map((type: any) => {
                if (remarks[type.id]) {
                  return (
                    <>
                      <div>
                        <b>{type.label}</b>
                      </div>
                      <p>{remarks[type.id].remark}</p>
                    </>
                  );
                }
              })}
            <hr />
          </>
        </>
      )}
    </>
  );
};

export default PrimaryReport;
