import React, { useEffect, useState } from "react";

//import Breadcrumbs
import { Loading } from "../../components/common/Loading";

import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";

import { FieldArray, Form, Formik } from "formik";
import { XSelect } from "../../components/common/inputs/XSelect";
import { Endpoints } from "../../utils/Endpoints";
import { ApiCore } from "../../helpers/api_helper";
import { AxiosError, AxiosResponse } from "axios";
import { getForms } from "../../utils/Helpers";
import { XTextBox } from "../../components/common/inputs/XTextBox";
import { XCheckbox } from "../../components/common/inputs/XCheckbox";
import { XFileInput } from "../../components/common/inputs/XFileInput";

const GridForm = (props: any) => {
  const [studentcategories, setStudentcategories] = useState<any>([]);
  const [marktypes, setMarktypes] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingMarktypes, setLoadingMarktypes] = useState<boolean>(true);

  const request = new ApiCore();

  useEffect(() => {
    request
      .get(Endpoints.schoolStudentCategories, {})
      .then((response: AxiosResponse) => {
        const { studentcategories, totalRecords } = response.data;
        let options: any = [];
        options = studentcategories.map((data: any) => ({
          value: data.id,
          text: data.name || data.taxid,
          data: JSON.stringify(data),
        }));
        setStudentcategories(options);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    request
      .get(Endpoints.marktypes, {})
      .then((response: AxiosResponse) => {
        const { marktypes  } = response.data;
        setMarktypes(marktypes);
        setLoadingMarktypes(false);
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  const terms = [
    {value: 'I', text: 'I'},
    {value: 'II', text: 'II'},
    {value: 'III', text: 'III'}
  ]

  const papers = [
    {value: 'I', text: 'I'},
    {value: 'II', text: 'II'},
    {value: 'III', text: 'III'},
    {value: 'IV', text: 'IV'},
    {value: 'V', text: 'V'},
    {value: 'VI', text: 'VI'}
  ]


  const handleSubmit = (values: any) => {
    let selectedMarkTypes = marktypes;
    
    if (values.marktypes && values.marktypes.length > 0) {
      selectedMarkTypes = [];
      marktypes.forEach((item: any) => {
        if (values.marktypes.includes(item.id)) {
          selectedMarkTypes.push(item);
        }
      })
    } 
    props.handleSearch(values, selectedMarkTypes);
  };

  return (
    <React.Fragment>
      <div>
        <Container fluid>
          {loading || loadingMarktypes && <Loading label="Loading marks. Please wait." />}
          {!loading && !loadingMarktypes && (
            <>
              <Formik
                initialValues={props.initialValues}
                enableReinitialize
                validateOnBlur
                onSubmit={(values: any) => handleSubmit(values)}
              >
                {({ isSubmitting, setFieldValue, values, errors, touched }) => (
                  <>
                    <Form>
                      <Row>
                        <Col lg={2}>
                          <XSelect
                            key="form"
                            id="form"
                            name={"form"}
                            options={getForms()}
                            label={"Class"}
                            touched={touched}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={2}>
                          <XTextBox
                            type="text"
                            key="stream"
                            id="stream"
                            name={"stream"}
                            label={"Stream"}
                            touched={touched}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={2}>
                          <XSelect
                            key="studentcategory_id"
                            id="studentcategory_id"
                            name={"studentcategory_id"}
                            options={studentcategories}
                            dataType={"studentcategories"}
                            label={"Category"}
                            touched={touched}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={1}>
                          <XSelect
                            key="term"
                            id="term"
                            name={"term"}
                            options={terms}
                            label={"Term"}
                            touched={touched}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={1}>
                          <XTextBox
                            type="text"
                            key="year"
                            id="year"
                            name={"year"}
                            label={"Year"}
                            touched={touched}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={1}>
                          <XSelect
                            key="paper"
                            id="paper"
                            name={"paper"}
                            options={papers}
                            label={"Paper"}
                            touched={touched}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={3}>
                          <XFileInput
                            key="file"
                            id="file"
                            name={"file"}
                            label={"Upload Excel File"}
                            onChange={
                              (event: any) => {
                                setFieldValue('file', event.currentTarget.files[0]);
                              }
                            }
                            errors={errors}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={2}>
                          <XSelect
                            key="subject"
                            id="subject"
                            name={"subject"}
                            dataSrc={Endpoints.subjects}
                            dataType={"subjects"}
                            label={"Subject"}
                            touched={touched}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={2}>
                          <XCheckbox
                            key="excel"
                            id="excel"
                            name={"excel"}
                            label={"Download Excel"}
                            value="1"
                            includeTopPadding={true}
                            onChange={setFieldValue}
                            checked={values.excel == 1}
                            size={{}}
                          />
                        </Col>
                        <FieldArray
                          name="marktypes"
                          render={arrayHelpers => (
                            <>
                            {marktypes && marktypes.map((item: any, index: number) => {
                              return <Col lg={1} key={index}>
                                <XCheckbox
                                  key={`marktypes.${index}`}
                                  id={`marktypes.${index}`}
                                  name={`marktypes`}
                                  label={item.label}
                                  value={item.id}
                                  checked={values.marktypes && values.marktypes.includes(item.id)}
                                  includeTopPadding={true}
                                  onChange={(field: any, value: any) => {
                                    if (value && value > 0) {
                                      arrayHelpers.push(item.id)
                                    } else {
                                      const idx = values.marktypes.indexOf(item.id);
                                      arrayHelpers.remove(idx);
                                    }
                                  }}
                                  size={{}}
                                />
                              </Col>
                            })}
                            </>
                          )}  
                        />                        
                        <Col lg={2}>
                          <div>&nbsp;</div>
                          <Button
                            disabled={false}
                            type="submit"
                            className="btn btn-primary"
                          >
                            Next
                          </Button>
                        </Col>
                      </Row>
                    
                          {props.topic ? <div><hr/>{props.topic.title}</div> : <></>}
                    </Form>
                    <hr/>
                  </>
                )}
              </Formik>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default GridForm;
