import { XTextBox } from "../../components/common/inputs/XTextBox";
import "react-toastify/dist/ReactToastify.css";
import { XSelect } from "../../components/common/inputs/XSelect";

const AssessmentReport = (props: any) => {
  const { values, data, student, remarks, showRemarks = true } = props;

  const renderGeneralRow = (studentId: any, marks: any, marktypes: any) => {
    const paper = "I";
    return (
      <>
        {marktypes.map((mt: any, index: number) => {
          return (
            <>
              <td key={`sid${studentId}_${mt.id}`}>
                { marks.mark[paper][mt.id]
                  ? marks.mark[paper][mt.id].total
                  : ""}
              </td>
              <td>
              { marks.mark[paper][mt.id]
                  ? marks.mark[paper][mt.id].grd
                  : ""}
              </td>
            </>
          );
        })}
      </>
    );
  };

  const records = data.records[student.id];
  const weekly_records = data.weekly[student.id];
  const test_records = data.tests[student.id];
  return (
    <>
      <div>
        <strong>{student.fullname}</strong>&nbsp;&nbsp;&nbsp;
        <strong>{student.studentid}</strong>
      </div>
      <hr />
      {records && (
        <>
          <>
            <table className="table">
              <thead>
                <tr>
                  <th>Subject</th>
                  {data.marktypes.map((mt: any, index: number) => {
                    return <><th key={index}>{mt.label}</th><th>GRD</th></>;
                  })}
                </tr>
              </thead>
              <tbody>
                {Object.keys(records).map((subjectId: any) => {
                  const marks = records[subjectId];
                  return (
                    <tr key={subjectId}>
                      <td>{data.subjects[subjectId].name}</td>
                      {renderGeneralRow(student.id, marks, data.marktypes)}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        </>
      )}
      <hr />
      {weekly_records && (
        <>
          <>
            <b>WEEKEND ASSESSMENT</b>
            <table className="table">
              <thead>
                <tr>
                  <th>Subject</th>
                  {data.marktypes_weekly.map((mt: any, index: number) => {
                    return <><th key={index}>{mt.label}</th><th>GRD</th></>;
                  })}
                </tr>
              </thead>
              <tbody>
                {Object.keys(weekly_records).map((subjectId: any) => {
                  const marks = weekly_records[subjectId];
                  return (
                    <tr key={subjectId}>
                      <td>{data.subjects[subjectId].name}</td>
                      {renderGeneralRow(student.id, marks, data.marktypes_weekly)}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        </>
      )}
      <hr />
      {test_records && (
        <>
          <>
          <b>TEST</b>
            <table className="table">
              <thead>
                <tr>
                  <th>Subject</th>
                  {data.marktypes_tests.map((mt: any, index: number) => {
                    return <><th key={index}>{mt.label}</th><th>GRD</th></>;
                  })}
                </tr>
              </thead>
              <tbody>
                {Object.keys(test_records).map((subjectId: any) => {
                  const marks = test_records[subjectId];
                  return (
                    <tr key={subjectId}>
                      <td>{data.subjects[subjectId].name}</td>
                      {renderGeneralRow(student.id, marks, data.marktypes_tests)}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        </>
      )}
      <hr />
      {remarks && showRemarks &&
        data.remarktypes.map((type: any) => {
          if (remarks[type.id]) {
            return (
              <>
                <div>
                  <b>{type.label}</b>
                </div>
                <p>{remarks[type.id].remark}</p>
              </>
            );
          }
        })}
    </>
  );
};

export default AssessmentReport;
