import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
  Button,
} from "reactstrap";
import MetaTags from "react-meta-tags";

//Import Breadcrumb
import Breadcrumbs from "../../components/common/Breadcrumb";
import "../../../src/assets/scss/custom/plugins/_datatables.scss";
import { ApiCore } from "../../helpers/api_helper";
import { Loading } from "../../components/common/Loading";
import { AxiosResponse } from "axios";
import { Endpoints } from "../../utils/Endpoints";
import { XTextBox } from "../../components/common/inputs/XTextBox";
import { Form, Formik } from "formik";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { XSelect } from "../../components/common/inputs/XSelect";
import { currentTenant, getForms } from "../../utils/Helpers";
import { XCheckbox } from "../../components/common/inputs/XCheckbox";
import PrimaryReport from "../Reports/primary";
import { XSearchableSelect } from "../../components/common/inputs/XSearchableSelect";

const Otherstats = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const ref = React.useRef<HTMLIFrameElement>(null);
  
  const [initialValues, setInitialValues] = useState<any>({
    student_id: "",
    stream: "",
    term: ""
  });

  const [data, setData] = useState<any>(null);

  const request = new ApiCore();

  const terms = [
    { value: "I", text: "I" },
    { value: "II", text: "II" },
    { value: "III", text: "III" },
  ];

  const categories = [
    { value: "assesment", text: "Assessment" },
    { value: "exam", text: "Exam" },
  ];

  let types = [{value: "", text: ""}];

  if (currentTenant().schooltype == "primary") {
    types = [
      {value: "division", text: "Division" },
      {value: "aggpoint", text: "Aggregate Analysis" },
      {value: "mgp", text: "Mean Grade Point"},
      {value: "weightedagg", text: "Weighted agg Performance"},
      {value: "classweightedagg", text: "Weighted Class agg Performance"},
      {value: "departmentweightedagg", text: "Weighted Department agg Performance"},
      {value: "aggpoint", text: "Aggregate Point Ranges"},
      {value: "progress", text: "Progressive Report"}
    ];
  } else {
    types = [
      {value: "scategory", text: "Subject Category"},
      {value: "average", text: "Class Average Ranges"},
      {value: "division", text: "Division"},
      {value: "caverage", text: "Subject Class Average"},
      {value: "mgp", text: "Mean Grade Point"},
      {value: "ple", text: "PLE"},
      {value: "dcpf", text: "Distinctions, C, P and F"},
      {value: "tdcpf", text: "Total Distinctions, C, P and F"},
      {value: "alevel", text: "A-Level Scores"},
      {value: "asummary", text: "A-Level Summary of Results"},
      {value: "subjectmean", text: "A-Level Subject Mean"},
      {value: "paper", text: "By Paper"},
      {value: "aggpoint", text: "Aggregate Point Ranges"},
      {value: "progress", text: "Progressive Report"},
      {value: "weightedagg", text: "Weighted agg Performance"},
      {value: "classweightedagg", text: "Weighted Class agg Performance"},
      {value: "departmentweightedagg", text: "Weighted Department agg Performance"}
    ];
  }

  const handleSubmit = (values: any) => {
    if (!values.html || values.html != 1) {
      delete values.html;
    }
    setInitialValues(values);
    toast
      .promise(request.get(Endpoints.otherstats, { params: values }, values.html ? "json" : "arraybuffer"), {
        pending: "Please wait..",
        success: {
          render({ data }) {
            return "The report is ready";
          },
        },
        error: {
          render({ data }) {
            return data;
          },
        },
      })
      .then((response: AxiosResponse) => {
        if (values.html) {
          setData(response.data);
        } else {
          const type = response.headers['content-type']
          const blob = new Blob([response.data], {type: type})
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = `otherstats-${values.form}-${values.stream}-${values.year}-${values.term}.pdf`
          link.click()
        }
      });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Mark Statistics - Treda</title>
        </MetaTags>

        <Container fluid>
          <Breadcrumbs title="Marks" breadcrumbItem="List" />
          <Row>
            <Col md={12} lg={12} xl={12}>
              <Card>
                <CardHeader className="justify-content-between d-flex align-items-center">
                  <CardTitle className="h4">Mark statistics</CardTitle>
                </CardHeader>
                <CardBody>
                  {loading && <Loading label="Loading marks. Please wait." />}
                  {!loading && (
                    <>
                      <Row className="mb-2">
                        <Col xl="12">
                          <ToastContainer />
                        </Col>
                      </Row>
                      <Formik
                        initialValues={initialValues}
                        enableReinitialize
                        validateOnBlur
                        onSubmit={(values: any) => handleSubmit(values)}
                      >
                        {({
                          isSubmitting,
                          setFieldValue,
                          values,
                          errors,
                          touched,
                        }) => (
                          <>
                            <Form>
                              <Row>
                                <Col lg={2}>
                                  <XSelect
                                    key="form"
                                    id="form"
                                    name={"form"}
                                    options={getForms()}
                                    label={"Class"}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={1}>
                                  <XTextBox
                                    type="text"
                                    key="stream"
                                    id="stream"
                                    name={"stream"}
                                    label={"Stream"}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={1}>
                                  <XSelect
                                    key="term"
                                    id="term"
                                    name={"term"}
                                    options={terms}
                                    label={"Term"}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={1}>
                                  <XTextBox
                                    type="text"
                                    key="year"
                                    id="year"
                                    name={"year"}
                                    label={"Year"}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={2}>
                                  <XSelect
                                    key="type"
                                    id="type"
                                    name={"type"}
                                    options={types}
                                    label={"Category"}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={2}>
                                  <XSelect
                                    key="marktype_id"
                                    id="marktype_id"
                                    name={"marktype_id"}
                                    dataSrc={Endpoints.marktypes}
                                    dataType={"marktypes"}
                                    labelField={"label"}
                                    label={"Mark Type"}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={2}>
                                  <XTextBox
                                    type="text"
                                    key="weight"
                                    id="weight"
                                    name={"weight"}
                                    label={"Weight"}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={2}>
                                  <XCheckbox
                                    key="html"
                                    id="html"
                                    name={"html"}
                                    label={"Preview"}
                                    value="1"
                                    includeTopPadding={true}
                                    onChange={setFieldValue}
                                    checked={values.html == 1}
                                    size={{}}
                                  />
                                </Col>
                                <Col lg={1}>
                                  <div>&nbsp;</div>
                                  <Button
                                    disabled={false}
                                    type="submit"
                                    className="btn btn-primary"
                                  >
                                    Next
                                  </Button>
                                </Col>
                              </Row>
                            </Form>
                          </>
                        )}
                      </Formik>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
          {data && (
          <Row>
            <Col md={12} lg={12} xl={12}>
              <Card>
                <CardBody>
                <iframe ref={ref} srcDoc={data} width="100%" height="800px"  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Otherstats;
