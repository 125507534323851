import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
  Button,
} from "reactstrap";
import MetaTags from "react-meta-tags";

//Import Breadcrumb
import Breadcrumbs from "../../components/common/Breadcrumb";
import "../../../src/assets/scss/custom/plugins/_datatables.scss";
import { ApiCore } from "../../helpers/api_helper";
import { Loading } from "../../components/common/Loading";
import { AxiosError, AxiosResponse } from "axios";
import { Endpoints } from "../../utils/Endpoints";
import { XTextBox } from "../../components/common/inputs/XTextBox";
import { XTextArea } from "../../components/common/inputs/XTextArea";
import { Form, Formik } from "formik";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { XSelect } from "../../components/common/inputs/XSelect";
import { getForms } from "../../utils/Helpers";
import { XCheckbox } from "../../components/common/inputs/XCheckbox";
import PrimaryReport from "../Reports/primary";
import { useLocation } from "react-router-dom";
import AssessmentReport from "../Reports/assessment";
import { XSearchableSelect } from "../../components/common/inputs/XSearchableSelect";

const DeleteComments = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<any>({});
  const request = new ApiCore();

  const terms = [
    { value: "I", text: "I" },
    { value: "II", text: "II" },
    { value: "III", text: "III" },
  ];

  const handleSubmit = (values: any) => {
    setInitialValues(values);
      toast
        .promise(request.create(Endpoints.removeRemarks, values), {
          pending: "Please wait..",
          success: {
            render({ data }) {
              return "Remarks have been deleted successfully";
            },
          },
          error: {
            render({ data }) {
              return data;
            },
          },
        })
        .then((response: AxiosResponse) => {
          
        });
  };

  const getStudentsEndpoint = (values: any) => {
    let url = Endpoints.students + "?";

    if (values.form) {
      url += `form=${values.form}&`;
    }

    if (values.stream) {
      url += `stream=${values.stream}&`;
    }

    url = url.replace(/&$/,"");

    return url;
  }

  const handleSearch = (values: any) => {
    if(window.confirm("This operation can not be reversed. Do you really want to proceed with it?")) {
      handleSubmit(values);
      return;
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Report comments - Treda</title>
        </MetaTags>

        <Container fluid>
          <Breadcrumbs title="Marks" breadcrumbItem="List" />
          <Row>
            <Col md={12} lg={12} xl={12}>
              <Card>
                <CardHeader className="justify-content-between d-flex align-items-center">
                  <CardTitle className="h4">Report Comments</CardTitle>
                </CardHeader>
                <CardBody>
                  {loading && <Loading label="Loading marks. Please wait." />}
                  {!loading && (
                    <>
                      <Row className="mb-2">
                        <Col xl="12">
                          <ToastContainer />
                        </Col>
                      </Row>
                      <Formik
                        initialValues={initialValues}
                        enableReinitialize
                        validateOnBlur
                        onSubmit={(values: any) => handleSubmit(values)}
                      >
                        {({
                          isSubmitting,
                          setFieldValue,
                          values,
                          errors,
                          touched,
                        }) => (
                          <>
                            <Form>
                              <Row>
                              <Col lg={2}>
                                <XSearchableSelect
                                  allowAddNew={false}
                                  dataSrc={getStudentsEndpoint(values)}
                                  onChange={(value: any) => {
                                    setFieldValue("student_id", value.value);
                                  }}
                                  dataType={"students"}
                                  labelField="fullname"
                                  id="student_id"
                                  name="student_id"
                                  value="student_id"
                                  label="Student"
                                  touched={touched}
                                />
                                </Col>
                                <Col lg={2}>
                                  <XSelect
                                    key="form"
                                    id="form"
                                    name={"form"}
                                    options={getForms()}
                                    label={"Class"}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={2}>
                                  <XTextBox
                                    type="text"
                                    key="stream"
                                    id="stream"
                                    name={"stream"}
                                    label={"Stream"}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={1}>
                                  <XSelect
                                    key="term"
                                    id="term"
                                    name={"term"}
                                    options={terms}
                                    label={"Term"}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={1}>
                                  <XTextBox
                                    type="text"
                                    key="year"
                                    id="year"
                                    name={"year"}
                                    label={"Year"}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={2}>
                                  <XSelect
                                    key="remarktype_id"
                                    id="remarktype_id"
                                    name={"remarktype_id"}
                                    dataSrc={Endpoints.remarktypes}
                                    labelField={"lb"}
                                    dataType={"remarktypes"}
                                    label={"Remark Type"}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={2}>
                                  <div>&nbsp;</div>
                                  <Button
                                    disabled={false}
                                    type="submit"
                                    onClick={(e: any) => {
                                      handleSearch(values);
                                      e.preventDefault();
                                      return false;
                                    }}
                                    className="btn btn-primary"
                                  >
                                    Next
                                  </Button>
                                </Col>
                              </Row>
                            </Form>
                          </>
                        )}
                      </Formik>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DeleteComments;
