import React, { useEffect, useRef, useState } from "react";
import MetaTags from "react-meta-tags";

//import Breadcrumbs
import Breadcrumbs from "../../components/common/Breadcrumb";
import { Loading } from "../../components/common/Loading";

import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";

import { FieldArray, Form, Formik } from "formik";
import { XSelect } from "../../components/common/inputs/XSelect";
import { XTextBox } from "../../components/common/inputs/XTextBox";
import { Endpoints } from "../../utils/Endpoints";
import { ApiCore } from "../../helpers/api_helper";
import { getForms } from "../../utils/Helpers";
import { ISelectOption } from "../../interfaces/inputs/ISelectOption";
import { AxiosError, AxiosResponse } from "axios";
import { useHistory, useLocation } from "react-router-dom";
import { forEach } from "lodash";

const NewGrade = () => {
  const [subjects, setSubjects] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingGrade, setLoadingGrade] = useState<boolean>(true);
  const params = new URLSearchParams(useLocation().search);

  const history = useHistory();

  const request = new ApiCore();

  useEffect(() => {
    request
      .get(Endpoints.subjects, {})
      .then((response: AxiosResponse) => {
        let options: any = [];
        options = response.data.subjects.map((data: any) => ({
          value: data.id,
          text: data.name,
          data: JSON.stringify(data),
        }));
        setSubjects(options);
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);


  const grades = params.get("type") && params.get("type") == "lower" ? ["A+","A","B+","B","C+","C","D+","D"] : ["D1","D2","C3","C4","C5","C6","P7","P8","F9"];

  let initialValues: any = {};
    initialValues = {
      subject_id: "",
      term: "",
      year: "",
      form: "",
      grades: grades.map((g) => {
        return {grade: g, lower: "", upper: ""}
      }),
    };

  const handleSubmit = (values: any) => {
    const request_body = values;

    request_body.lower = {};
    request_body.upper = {};

    request_body.grades.forEach((item: any) => {
      request_body.lower[item.grade] = item.lower;
      request_body.upper[item.grade] = item.upper;
    })

    delete request_body.grades;

    request
      .create(Endpoints.grades, request_body)
      .then((response: AxiosResponse) => {
        history.push("/grades");
      })
      .catch((error: AxiosError) => {
        console.log(error);
      });
  };

  const terms: ISelectOption[] = [
    { value: "I", text: "I" },
    { value: "II", text: "II" },
    { value: "III", text: "III" },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>New Grade | Treda</title>
        </MetaTags>

        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Grades" breadcrumbItem="New Grade" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {(loading) && (
                    <Loading label="Loading grade. Please wait." />
                  )}
                  {!loading && (
                    <>
                      <Formik
                        initialValues={initialValues}
                        enableReinitialize
                        validateOnBlur
                        onSubmit={(values: any) => handleSubmit(values)}
                      >
                        {({
                          isSubmitting,
                          setFieldValue,
                          values,
                          errors,
                          touched,
                        }) => (
                          <>
                            <Form>
                              <Row>
                                <Col lg={3}>
                                  <XSelect
                                    key="form"
                                    id="form"
                                    name={"form"}
                                    options={getForms()}
                                    label={"Class"}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={3}>
                                <XTextBox
                                    type="text"
                                    key="year"
                                    id="year"
                                    name={"year"}
                                    label={"Year"}
                                    touched={touched}
                                    value={values.year}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={3}>
                                  <XSelect
                                    key="term"
                                    id="term"
                                    name={"term"}
                                    options={terms}
                                    label={"Term"}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={3}>
                                  <XSelect
                                    key="subject_id"
                                    id="subject_id"
                                    name={"subject_id"}
                                    options={subjects}
                                    dataType={"subjects"}
                                    label={"Subject"}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                              </Row>

                              <Row>
                                <Col lg={12}>
                                  <div
                                    className={
                                      "mt-4 border-top border-bottom pt-2 bg-light"
                                    }
                                  >
                                    <Row>
                                      <Col lg={2} className="px-4">
                                        <h6>Grade</h6>
                                      </Col>
                                      <Col lg={2}>
                                        <h6>Lower</h6>
                                      </Col>
                                      <Col lg={2}>
                                        <h6>Upper</h6>
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>

                                <Col lg={12}>
                                  <FieldArray
                                    name={"grades"}
                                    render={arrayHelpers => (
                                      <div className={"py-3"}>
                                        {values.grades &&
                                          values.grades.map(
                                            (item: any, index: number) => (
                                              <Row key={index}>
                                                <Col lg={2}>
                                                  <XTextBox
                                                    id={`grades.${index}.grade`}
                                                    type={"text"}
                                                    name={`grades.${index}.grade`}
                                                    touched={touched}
                                                    errors={errors}
                                                  />
                                                </Col>
                                                <Col lg={2}>
                                                  <XTextBox
                                                    id={`grades.${index}.lower`}
                                                    type={"text"}
                                                    name={`grades.${index}.lower`}
                                                    touched={touched}
                                                    errors={errors}
                                                  />
                                                </Col>
                                                <Col lg={2}>
                                                  <XTextBox
                                                    id={`grades.${index}.upper`}
                                                    type={"text"}
                                                    name={`grades.${index}.upper`}
                                                    touched={touched}
                                                    errors={errors}
                                                  />
                                                </Col>
                                              </Row>
                                            )
                                          )}
                                      </div>
                                    )}
                                  />
                                </Col>
                              </Row>
                              <div className="modal-footer">
                                <Button
                                  disabled={isSubmitting}
                                  type="submit"
                                  className="btn btn-primary"
                                >
                                  Save
                                </Button>
                              </div>
                            </Form>
                          </>
                        )}
                      </Formik>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default NewGrade;
