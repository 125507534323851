import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Container,
  DropdownToggle,
  DropdownMenu, DropdownItem, UncontrolledDropdown
} from "reactstrap";
import MetaTags from "react-meta-tags";
import { Link, useHistory } from "react-router-dom";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import Breadcrumbs from "../../components/common/Breadcrumb";
import "../../../src/assets/scss/custom/plugins/_datatables.scss";
import { ApiCore } from "../../helpers/api_helper";
import { Loading } from "../../components/common/Loading";
import { AxiosResponse } from "axios";
import XModal from "../../components/common/XModal";
import CreateForm from "../../components/forms/CreateForm";
import { Endpoints } from "../../utils/Endpoints";
import { RemarktypeFields } from "../../fields/RemarktypeFields";

const Remarktypes = () => {

  const [loading, setLoading] = useState<boolean>(true);
  const [remarktypes, setRemarktypes] = useState<any>([]);
  const [showRemarktypeDialog, setShowRemarktypeDialog] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<any>({});
  const [remarktype, setRemarktype] = useState<any>(null);
  const history = useHistory();

  const columns = [
    {
      dataField: "",
      text: "Name",
      formatter: (cellContent: any, item: any) => item.parent && item.parent != 0 ? "  > " + item.label : `${item.label}`
    },
    {
      dataField: "category",
      text: "Category"
    },
    {
      dataField: "position",
      text: "Position"
    },
    {
      dataField: "forms",
      text: "Classes"
    },
    {
      dataField: "displaytype",
      text: "Display Type"
    },
    {
      dataField: "inputtype",
      text: "Input Type"
    },
    {
      dataField: "active",
      text: "Active"
    },
    {
      dataField: "",
      text: "",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, product: any) => <UncontrolledDropdown>
        <DropdownToggle className="card-drop" tag="a">
          <i className="mdi mdi-dots-horizontal font-size-18 text-muted"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem to="#" onClick={() => handleOption(product, "edit")}>
            <i className="mdi mdi-pencil font-size-16 text-success me-1"></i> Edit
          </DropdownItem>
          <DropdownItem to="#" onClick={() => handleOption(product, "new child")}>
            <i className="mdi mdi-pencil font-size-16 text-success me-1"></i> Add Sub category
          </DropdownItem>
          <DropdownItem to="#" onClick={() => handleOption(product, "mark")}>
            <i className="mdi mdi-pencil font-size-16 text-success me-1"></i> Record Comments
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    }
  ];


  const handleOption = (item: any, type: string) => {
    if (type == "edit") {
      setRemarktype(null)
      setInitialValues(item);
      setShowRemarktypeDialog(true);
    } else if (type == "new") {
      setInitialValues({});
      setShowRemarktypeDialog(true);
      setRemarktype(null);
    } else if (type == "new child") {
      setRemarktype(item);
      setInitialValues({ parent: item.id, active: true, category: item.category });
      setShowRemarktypeDialog(true);
    } else if (type == "mark") {
      history.push("/comments/report?remarktype_id=" + item.id);
    }
  }

  const defaultSorted: any = [
    {
      dataField: "name",
      order: "asc"
    }
  ];

  const pageOptions: any = {
    sizePerPage: 25,
    totalSize: remarktypes.length,
    custom: true
  };

  const selectRow: any = {
    mode: "checkbox"
  };

  const request = new ApiCore();
  const { SearchBar } = Search;

  useEffect(() => {

    request.get(Endpoints.remarktypes, {})
      .then((response: AxiosResponse) => {

        const { remarktypes, totalRecords } = response.data;
        setRemarktypes(remarktypes)
        setLoading(false)

      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleUpdate = (item: any) => {
    const items = remarktypes.map((i: any) => {
      if (i.id === item.id) {
        return { ...i, ...item };
      }

      return i;
    })
    setRemarktypes(items);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Remark types - Treda</title>
        </MetaTags>

        <Container fluid>
          <Breadcrumbs title="Remark types" breadcrumbItem="List" />

          <XModal title={remarktype ? "Add Sub Category - " + remarktype.label : "Add Category"}
            width={"lg"}
            open={showRemarktypeDialog}
            toggle={() => setShowRemarktypeDialog(!showRemarktypeDialog)}
            onClose={() => setShowRemarktypeDialog(false)}>

            <CreateForm
              fields={RemarktypeFields}
              initialValues={initialValues}
              submitEndpoint={initialValues.id ? Endpoints.remarktypes + "/" + initialValues.id : Endpoints.remarktypes}
              submitButtonLabel={initialValues.id ? "Edit Category" : "Add Category"}
              debug={false}
              handleUpdate={handleUpdate}
              onClose={() => setShowRemarktypeDialog(false)} />

          </XModal>

          <Row>
            <Col md={12} lg={12} xl={12}>
              <Card>
                <CardHeader className="justify-content-between d-flex align-items-center">
                  <CardTitle className="h4">
                    Remark types
                  </CardTitle>

                  <UncontrolledDropdown className="mt-4 mt-sm-0">
                    <DropdownToggle tag="a" className="btn btn-light">
                      <i className="fa fa-ellipsis-v"></i>
                    </DropdownToggle>

                    <DropdownMenu className="dropdown-menu-end">
                      <DropdownItem to="#" onClick={() => handleOption(null, "new")}>
                        Add Category
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </CardHeader>
                <CardBody>
                  {loading && <Loading label="Loading categories. Please wait." />}
                  {!loading &&
                    <>
                      <table className="table">
                        <thead>
                          <tr>
                            <th><b>Name</b></th>
                            <th><b>Category</b></th>
                            <th><b>Position</b></th>
                            <th><b>Classes</b></th>
                            <th><b>Display type</b></th>
                            <th><b>Input type</b></th>
                            <th><b>Active</b></th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {remarktypes && remarktypes.map((item: any) => {
                            return (<><tr key={item.id}>
                              <td>{item.label}</td>
                              <td>{item.category}</td>
                              <td>{item.position}</td>
                              <td>{item.forms}</td>
                              <td>{item.displaytype}</td>
                              <td>{item.inputtype}</td>
                              <td>{item.active}</td>
                              <td>
                                <UncontrolledDropdown>
                                  <DropdownToggle className="card-drop" tag="a">
                                    <i className="mdi mdi-dots-horizontal font-size-18 text-muted"></i>
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-end">
                                    <DropdownItem to="#" onClick={() => handleOption(item, "edit")}>
                                      <i className="mdi mdi-pencil font-size-16 text-success me-1"></i> Edit
                                    </DropdownItem>
                                    <DropdownItem to="#" onClick={() => handleOption(item, "new child")}>
                                      <i className="mdi mdi-pencil font-size-16 text-success me-1"></i> Add Sub category
                                    </DropdownItem>
                                    <DropdownItem to="#" onClick={() => handleOption(item, "mark")}>
                                      <i className="mdi mdi-pencil font-size-16 text-success me-1"></i> Record Comments
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                              {item.children && item.children.map((child: any) => {
                                return (
                                  <tr key={child.id}>
                                    <td>&nbsp;&nbsp;&nbsp;&nbsp;{child.label}</td>
                                    <td>{child.category}</td>
                                    <td>{child.position}</td>
                                    <td>{child.forms}</td>
                                    <td>{child.displaytype}</td>
                                    <td>{child.inputtype}</td>
                                    <td>{child.active}</td>
                                    <td>
                                      <UncontrolledDropdown>
                                        <DropdownToggle className="card-drop" tag="a">
                                          <i className="mdi mdi-dots-horizontal font-size-18 text-muted"></i>
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-end">
                                          <DropdownItem to="#" onClick={() => handleOption(child, "edit")}>
                                            <i className="mdi mdi-pencil font-size-16 text-success me-1"></i> Edit
                                          </DropdownItem>
                                          <DropdownItem to="#" onClick={() => handleOption(child, "new child")}>
                                            <i className="mdi mdi-pencil font-size-16 text-success me-1"></i> Add Sub category
                                          </DropdownItem>
                                          <DropdownItem to="#" onClick={() => handleOption(child, "mark")}>
                                            <i className="mdi mdi-pencil font-size-16 text-success me-1"></i> Record Comments
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                    </td>
                                  </tr>
                                )
                              })}
                            </>)
                          })}
                        </tbody>
                      </table>
                    </>
                  }

                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>

      </div>
    </React.Fragment>
  );
};

export default Remarktypes;