import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
  Button,
} from "reactstrap";
import MetaTags from "react-meta-tags";

//Import Breadcrumb
import Breadcrumbs from "../../components/common/Breadcrumb";
import "../../../src/assets/scss/custom/plugins/_datatables.scss";
import { ApiCore } from "../../helpers/api_helper";
import { Loading } from "../../components/common/Loading";
import { AxiosError, AxiosResponse } from "axios";
import { Endpoints } from "../../utils/Endpoints";
import { XTextBox } from "../../components/common/inputs/XTextBox";
import { XTextArea } from "../../components/common/inputs/XTextArea";
import { Form, Formik } from "formik";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { XSelect } from "../../components/common/inputs/XSelect";
import { getForms } from "../../utils/Helpers";
import { XCheckbox } from "../../components/common/inputs/XCheckbox";
import PrimaryReport from "../Reports/primary";
import { useLocation } from "react-router-dom";
import AssessmentReport from "../Reports/assessment";

const ReportComments = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [responses, setResponses] = useState<any>({});
  const [initialValues, setInitialValues] = useState<any>({});
  const [data, setData] = useState<any>(null);
  const params = new URLSearchParams(useLocation().search);
  const request = new ApiCore();

  const terms = [
    { value: "I", text: "I" },
    { value: "II", text: "II" },
    { value: "III", text: "III" },
  ];

  const categories = [
    { value: "assesment", text: "Assessment" },
    { value: "exam", text: "Exam" },
    { value: "mid term", text: "Mid term" },
  ];

  const handleSave = (values: any, comment: String, remarktype: any, student: any) => {
    if (comment.length > 0) {
      values.comment = {}
      values.comment[`rt_${remarktype.id}`] = {}
      values.comment[`rt_${remarktype.id}`][`s_${student.id}`] = comment;
      request.create(Endpoints.remarks, values).then((response: AxiosResponse) => {
        let r = responses;
        if (!r[`rt_${remarktype.id}`]) {
          r[`rt_${remarktype.id}`] = {};
        }

        if (!r[`rt_${remarktype.id}`][`s_${student.id}`]) {
          r[`rt_${remarktype.id}`][`s_${student.id}`] = "";
        }

        r[`rt_${remarktype.id}`][`s_${student.id}`] = "success";
        setResponses(r);
      }).catch((error: AxiosError) => {
        console.log(error)
      })
    }
  }

  const handleSubmit = (values: any) => {
    setInitialValues(values);
    if (values.comment) {
      toast
        .promise(request.create(Endpoints.remarks, values), {
          pending: "Please wait..",
          success: {
            render({ data }) {
              let message: any = "";
              if (values.excel && values.excel == 1) {
                message = "Ready...";
              }
              return message;
            },
          },
          error: {
            render({ data }) {
              return data;
            },
          },
        })
        .then((response: AxiosResponse) => {
          delete values.comment;
          setInitialValues(values);
        });
    } else {
      toast
        .promise(request.get(Endpoints.remarks, { params: values }), {
          pending: "Please wait..",
          success: {
            render({ data }) {
              let message: any = "";
              if (values.excel && values.excel == 1) {
                message = "Ready...";
              }
              return message;
            },
          },
          error: {
            render({ data }) {
              return data;
            },
          },
        })
        .then((response: AxiosResponse) => {

          values.comment = {};
          Object.keys(response.data.remarks).map((studentId: any) => {
            Object.keys(response.data.remarks[studentId]).map(
              (typeId: any) => {
                if (typeId == values.remarktype_id) {
                  if (!values.comment[`rt_${typeId}`]) {
                    values.comment[`rt_${typeId}`] = {};
                  }
                  values.comment[`rt_${typeId}`][`s_${studentId}`] = response.data.remarks[studentId][typeId].remark;
                }
              }
            );
          });

          setInitialValues(values);
          setData(response.data);
          console.log(response.data);
        });
    }
  };

  const getAutoCommentList = () => {
    let options: any = [];
    data.autocomments.map((d: any) => {
      options.push({ text: d.comment, value: d.comment });
    });
    return options;
  };

  useEffect(() => {
    if (params.get("remarktype_id")) {
      setInitialValues({ remarktype_id: params.get("remarktype_id"), ta: 1 });
    }
  }, []);

  const renderGeneralRow = (studentId: any, marks: any) => {
    const paper = "I";
    return <>
      {data.marktypes.map(
        (mt: any, index: number) => {
          return (
            <td
              key={`sid${studentId}_${mt.id}`}
            >
              {initialValues.rawmark &&
                initialValues.rawmark ==
                1
                ? marks.mark[paper][
                  mt.id
                ].raw
                : marks.mark[paper][
                  mt.id
                ].computed}
            </td>
          );
        }
      )}
      <td>
        {marks.mark[paper].total}
      </td>
      <td>
        {marks.mark[paper].grade}
      </td>
    </>
  }

  const renderAlevelRow = (studentId: any, marks: any) => {
    const paper = "I";
    return <>
      {data.marktypes.map(
        (mt: any, index: number) => {
          return (
            <td
              key={`sid${studentId}_${mt.id}`}
            >
              {initialValues.rawmark &&
                initialValues.rawmark ==
                1
                ? marks.mark[paper][
                  mt.id
                ].raw
                : marks.mark[paper][
                  mt.id
                ].computed}
            </td>
          );
        }
      )}
      <td>
        {marks.mark[paper].total} {paper}
      </td>
      <td>
        {marks.mark[paper].grade}
      </td>
    </>
  }

  const handleSearch = (values: any) => {
    delete values.comment;
    handleSubmit(values);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Report comments - Treda</title>
        </MetaTags>

        <Container fluid>
          <Breadcrumbs title="Marks" breadcrumbItem="List" />
          <Row>
            <Col md={12} lg={12} xl={12}>
              <Card>
                <CardHeader className="justify-content-between d-flex align-items-center">
                  <CardTitle className="h4">Report Comments</CardTitle>
                </CardHeader>
                <CardBody>
                  {loading && <Loading label="Loading marks. Please wait." />}
                  {!loading && (
                    <>
                      <Row className="mb-2">
                        <Col xl="12">
                          <ToastContainer />
                        </Col>
                      </Row>
                      <Formik
                        initialValues={initialValues}
                        enableReinitialize
                        validateOnBlur
                        onSubmit={(values: any) => handleSubmit(values)}
                      >
                        {({
                          isSubmitting,
                          setFieldValue,
                          values,
                          errors,
                          touched,
                        }) => (
                          <>
                            <Form>
                              <Row>
                                <Col lg={2}>
                                  <XSelect
                                    key="form"
                                    id="form"
                                    name={"form"}
                                    options={getForms()}
                                    label={"Class"}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={2}>
                                  <XTextBox
                                    type="text"
                                    key="stream"
                                    id="stream"
                                    name={"stream"}
                                    label={"Stream"}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={2}>
                                  <XSelect
                                    key="studentcategory_id"
                                    id="studentcategory_id"
                                    name={"studentcategory_id"}
                                    dataSrc={Endpoints.schoolStudentCategories}
                                    dataType={"studentcategories"}
                                    label={"Student Category"}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={1}>
                                  <XSelect
                                    key="term"
                                    id="term"
                                    name={"term"}
                                    options={terms}
                                    label={"Term"}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={1}>
                                  <XTextBox
                                    type="text"
                                    key="year"
                                    id="year"
                                    name={"year"}
                                    label={"Year"}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={2}>
                                  <XSelect
                                    key="category"
                                    id="category"
                                    name={"category"}
                                    options={categories}
                                    label={"Report Type"}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={2}>
                                  <XSelect
                                    key="marktype_id"
                                    id="marktype_id"
                                    name={"marktype_id"}
                                    dataSrc={Endpoints.marktypes}
                                    dataType={"marktypes"}
                                    labelField={"label"}
                                    label={"Mark Type"}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={2}>
                                  <XSelect
                                    key="remarktype_id"
                                    id="remarktype_id"
                                    name={"remarktype_id"}
                                    dataSrc={Endpoints.remarktypes}
                                    labelField={"lb"}
                                    dataType={"remarktypes"}
                                    label={"Remark Type"}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={2}>
                                  <XCheckbox
                                    key="rawmark"
                                    id="rawmark"
                                    name={"rawmark"}
                                    label={"Display raw marks"}
                                    value="1"
                                    includeTopPadding={true}
                                    onChange={setFieldValue}
                                    checked={values.excel == 1}
                                    size={{}}
                                  />
                                </Col>
                                <Col lg={2}>
                                  <XCheckbox
                                    key="ta"
                                    id="ta"
                                    name={"ta"}
                                    label={"Display Long text"}
                                    value="1"
                                    includeTopPadding={true}
                                    onChange={setFieldValue}
                                    checked={values.ta == 1}
                                    size={{}}
                                  />
                                </Col>
                                <Col lg={2}>
                                  <div>&nbsp;</div>
                                  <Button
                                    disabled={false}
                                    type="submit"
                                    onClick={(e: any) => {
                                      handleSearch(values);
                                      e.preventDefault();
                                      return false;
                                    }}
                                    className="btn btn-primary"
                                  >
                                    Next
                                  </Button>
                                </Col>
                              </Row>
                              <hr />
                              {data && (
                                <>
                                  {data.students.map((student: any) => {
                                    return (<>
                                      {values.category == "assesment" ? <AssessmentReport values={values} data={data} student={student} remarks={data.remarks[student.id]} showRemarks={false} /> : <PrimaryReport values={values} data={data} student={student} remarks={data.remarks[student.id]} showRemarks={false} />}
                                      {data.remarktypes.map((type: any) => {
                                        const opts = getAutoCommentList();
                                        return <>
                                          {type.children && type.children.length > 0 ? (type.children.map((child: any) => {
                                            return <>
                                              <div>
                                                <b>{child.label}</b>
                                                {responses[`rt_${child.id}`] && responses[`rt_${child.id}`][`s_${student.id}`] && (
                                                  <span className="green"> <i className="fas fa-check"></i> Saved</span>
                                                )}
                                              </div><br />
                                              <div>
                                                {
                                                  (values.ta && values.ta == 1) || (child.inputtype && child.inputtype == "text") ? (
                                                    <XTextArea
                                                      id={`comment.${child.id}.${student.id}`}
                                                      name={`comment[rt_${child.id}][s_${student.id}]`}
                                                      key={`comment.${child.id}.${student.id}`}
                                                      rows="5"
                                                      handleBlur={event => {
                                                        handleSave(
                                                          values,
                                                          event.target.value,
                                                          child,
                                                          student
                                                        )
                                                      }}
                                                      size={{ lg: 12 }}
                                                      touched={touched}
                                                      errors={errors}
                                                    />
                                                  ) : (
                                                    <XTextBox
                                                      id={`comment.${child.id}.${student.id}`}
                                                      type={"text"}
                                                      name={`comment[rt_${child.id}][s_${student.id}]`}
                                                      key={`comment.${child.id}.${student.id}`}
                                                      handleBlur={event => {
                                                        handleSave(
                                                          values,
                                                          event.target.value,
                                                          child,
                                                          student
                                                        )
                                                      }}
                                                      onChange={(v: any) => {
                                                        setFieldValue(
                                                          `comment[rt_${child.id}][s_${student.id}]`,
                                                          v
                                                        );
                                                      }}
                                                      touched={touched}
                                                      errors={errors}
                                                    />
                                                  )
                                                }
                                              </div>
                                            </>
                                          })) : (<>
                                            <div>
                                              <b>{type.label}</b>
                                              {responses[`rt_${type.id}`] && responses[`rt_${type.id}`][`s_${student.id}`] && (
                                                <span className="green"> <i className="fas fa-check"></i> Saved</span>
                                              )}
                                            </div><br />
                                            {
                                              opts.length > 0 && (<div>
                                                <XSelect
                                                  id={`comment1.${student.studentid}`}
                                                  name={`comment1[${student.studentid}]`}
                                                  key={`comment1.${student.studentid}`}
                                                  options={getAutoCommentList()}
                                                  onChange={(field: any) => {
                                                    setFieldValue(`comment[rt_${type.id}][s_${student.id}]`, field.target.selectedOptions[0].value);
                                                  }}
                                                  touched={touched}
                                                  errors={errors}
                                                />
                                              </div>)
                                            }
                                            <div>
                                              {
                                                values.ta && values.ta == 1 ? (
                                                  <XTextArea
                                                    id={`comment.${type.id}.${student.id}`}
                                                    name={`comment[rt_${type.id}][s_${student.id}]`}
                                                    key={`comment.${type.id}.${student.id}`}
                                                    rows="5"
                                                    handleBlur={event => {
                                                      handleSave(
                                                        values,
                                                        event.target.value,
                                                        type,
                                                        student
                                                      )
                                                    }}
                                                    size={{ lg: 12 }}
                                                    touched={touched}
                                                    errors={errors}
                                                  />
                                                ) : (
                                                  <XTextBox
                                                    id={`comment.${type.id}.${student.id}`}
                                                    type={"text"}
                                                    name={`comment[rt_${type.id}][s_${student.id}]`}
                                                    key={`comment.${type.id}.${student.id}`}
                                                    handleBlur={event => {
                                                      handleSave(
                                                        values,
                                                        event.target.value,
                                                        type,
                                                        student
                                                      )
                                                    }}
                                                    onChange={(v: any) => {
                                                      setFieldValue(
                                                        `comment[rt_${type.id}][s_${student.id}]`,
                                                        v
                                                      );
                                                    }}
                                                    touched={touched}
                                                    errors={errors}
                                                  />
                                                )
                                              }

                                            </div>
                                          </>)}
                                        </>
                                      })}
                                      <hr /><br />
                                    </>)
                                  })}

                                  <hr />
                                  <Button
                                    disabled={false}
                                    type="submit"
                                    className="btn btn-primary"
                                  >
                                    SAVE
                                  </Button>
                                </>
                              )}
                            </Form>
                          </>
                        )}
                      </Formik>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ReportComments;
