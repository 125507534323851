import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Button,
} from "reactstrap";

import Breadcrumbs from "../../components/common/Breadcrumb";

import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";

//redux
import { AxiosResponse } from "axios";
import { ApiCore } from "../../helpers/api_helper";
import { Endpoints } from "../../utils/Endpoints";
import { amountToCurrency, formattedDate } from "../../utils/Helpers";
import { Link, useHistory, useLocation } from "react-router-dom";
import SearchGrade from "./search";

const Grades = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [grades, setGrades] = useState<any>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const params = new URLSearchParams(useLocation().search);
  const invtype = params.get("invtype") ? params.get("invtype") : "sale";

  const history = useHistory();

  const handleEdit = async (id: string) => {
    //history.push("/transactions/sales/new?id="+id);
  };

  const rowEvents = {
    onClick: (e: any, grade: any) => {
      history.push("/grades-detail/" + grade.id);
    },
  };

  const searchGrade = (values: any) => {
      request
        .get(Endpoints.grades, { params: values })
        .then((response: AxiosResponse) => {
          const { grades, totalRecords } = response.data;
          setGrades(grades);
          setTotalRecords(totalRecords);
          setLoading(false);
        })
        .catch(error => {
          console.error(error);
        });
  };

  const request = new ApiCore();

  const pageOptions = {
    sizePerPage: 100,
    totalSize: totalRecords, // replace later with size(users),
    custom: true,
  };

  const defaultSorted: any = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "asc", // desc or asc
    },
  ];

  const selectRow: any = {
    mode: "checkbox",
  };

  const gradesListColumns = [
    {
      text: "Subject",
      dataField: "subject.name",
      sort: true,
    },
    {
      text: "Class",
      dataField: "form" 
    },
    {
      text: "Term",
      dataField: "term",
      sort: true,
    },
    {
      text: "Year",
      dataField: "year"
    },
    {
      text: "Grade",
      dataField: "grade"
    },
    {
      text: "Lower",
      dataField: "lower"
    },
    {
      text: "Upper",
      dataField: "upper"
    },
    {
      dataField: "",
      text: "",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, grade: any) => (
        <UncontrolledDropdown>
          <DropdownToggle className="card-drop" tag="a">
            <i className="mdi mdi-dots-horizontal font-size-18 text-muted"></i>
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <DropdownItem to="#" onClick={() => handleEdit(grade.id)}>
              <i className="mdi mdi-pencil font-size-16 text-success me-1"></i>
              Edit
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
    },
  ];

  useEffect(() => {
    searchGrade({
      term: "",
      year: new Date().getFullYear(),
      form: ""
    });
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Grade List | Treda - React Admin & Dashboard Template</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Grades" breadcrumbItem="Grades List" />
          <Row>
            <Col lg="12">
              <Card>
                <CardHeader className="justify-content-between d-flex align-items-center">
                  <CardTitle className="h4">Grades</CardTitle>

                  <UncontrolledDropdown className="mt-4 mt-sm-0">
                    <DropdownToggle tag="a" className="btn btn-light">
                      <i className="fa fa-ellipsis-v"></i>
                    </DropdownToggle>

                    <DropdownMenu className="dropdown-menu-end">
                      <DropdownItem
                        to="#"
                        onClick={() => {
                          history.push("/grades/new");
                        }}
                      >
                        Add Grade
                      </DropdownItem>
                      <DropdownItem
                        to="#"
                        onClick={() => {
                          history.push("/grades/new?type=lower");
                        }}
                      >
                        Add Other Grades
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </CardHeader>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={grades}
                        columns={gradesListColumns}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row>
                              <Col xl="12">
                                <SearchGrade
                                  handleSearch={(values: any) => {
                                    searchGrade(values);
                                  }}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    rowEvents={rowEvents}
                                    defaultSorted={defaultSorted}
                                    classes={
                                      "table align-middle table-nowrap table-hover"
                                    }
                                    bordered={false}
                                    striped={true}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Grades;
