import { IField } from "../interfaces/inputs/IField";
import { Endpoints } from "../utils/Endpoints";
import { getForms } from "../utils/Helpers";

const categories = [
  {value: 'english', text: 'English'},
  {value: 'general', text: 'General'},
  {value: 'humanity', text: 'Humanity'},
  {value: 'subsidiary', text: 'subsidiary'},
  {value: 'mathematics', text: 'Mathematics'},
  {value: 'science', text: 'Science'},
]

const terms = [
  {value: 'I', text: 'I'},
  {value: 'II', text: 'II'},
  {value: 'II', text: 'III'}
]

export const AlevelscoreFields: IField[] = [
  { name: "total", type: "text", label: "Total", size: {lg: 4}, position: 3 },
  { name: "score", type: "text", label: "Score", size: {lg: 4}, position: 6 },
  { name: "papercount", type: "number", label: "Papers", size: {lg: 4}, position: 6 },
].sort((a: IField, b: IField) => a.position - b.position);