import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
  Button,
} from "reactstrap";
import MetaTags from "react-meta-tags";

//Import Breadcrumb
import Breadcrumbs from "../../components/common/Breadcrumb";
import "../../../src/assets/scss/custom/plugins/_datatables.scss";
import { ApiCore } from "../../helpers/api_helper";
import { Loading } from "../../components/common/Loading";
import { AxiosResponse } from "axios";
import { Endpoints } from "../../utils/Endpoints";
import { XTextBox } from "../../components/common/inputs/XTextBox";
import { Form, Formik } from "formik";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { XSelect } from "../../components/common/inputs/XSelect";
import { getForms } from "../../utils/Helpers";
import { XCheckbox } from "../../components/common/inputs/XCheckbox";

const SubjectComments = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<any>({});
  const [data, setData] = useState<any>(null);

  const request = new ApiCore();

  const terms = [
    { value: "I", text: "I" },
    { value: "II", text: "II" },
    { value: "III", text: "III" },
  ];

  const categories = [
    { value: "assesment", text: "Assessment" },
    { value: "exam", text: "Exam" },
  ];

  const handleSubmit = (values: any) => {
    setInitialValues(values);
    if (values.comment) {
      toast
        .promise(request.create(Endpoints.markcomments, values), {
          pending: "Please wait..",
          success: {
            render({ data }) {
              let message: any = "";
              if (values.excel && values.excel == 1) {
                message = "Ready...";
              }
              return message;
            },
          },
          error: {
            render({ data }) {
              return data;
            },
          },
        })
        .then((response: AxiosResponse) => {
          delete values.comment;
          setInitialValues(values);
        });
    } else {
      toast
        .promise(request.get(Endpoints.markcomments, { params: values }), {
          pending: "Please wait..",
          success: {
            render({ data }) {
              let message: any = "";
              if (values.excel && values.excel == 1) {
                message = "Ready...";
              }
              return message;
            },
          },
          error: {
            render({ data }) {
              return data;
            },
          },
        })
        .then((response: AxiosResponse) => {
          values.comment = {};

          response.data.students.map((student: any) => {
            if (response.data.records[student.id.toString()]) {
              Object.keys(response.data.records[student.id.toString()]).map(
                (subjectId: any) => {
                  const marks = response.data.records[student.id.toString()][subjectId];
                  values.comment[student.studentid] = marks.comment;
                }
              );              
            }
          })

          setInitialValues(values);
          setData(response.data);
        });
    }
  };

  const getAutoCommentList = (score: string) => {
    let options: any = [];
    data.autocomments.map((d: any) => {
      if (d.score.toLowerCase() == score.toLowerCase()) {
        options.push({ text: d.comment, value: d.comment });
      }
    });
    return options;
  };

  const renderGeneralRow = (studentId: any, marks: any) => {
    const paper = "I";
    return (
      <>
        {data.marktypes.map((mt: any, index: number) => {
          return (
            <td key={`sid${studentId}_${mt.id}`}>
              {initialValues.rawmark && initialValues.rawmark == 1
                ? marks.mark[paper][mt.id].raw
                : marks.mark[paper][mt.id].computed}
            </td>
          );
        })}
        <td>{marks.mark[paper].total}</td>
        <td>{marks.mark[paper].grade}</td>
      </>
    );
  };

  const renderAlevelRow = (studentId: any, marks: any) => {
    const paper = "I";
    return (
      <>
        {data.marktypes.map((mt: any, index: number) => {
          return (
            <td key={`sid${studentId}_${mt.id}`}>
              {initialValues.rawmark && initialValues.rawmark == 1
                ? marks.mark[paper][mt.id].raw
                : marks.mark[paper][mt.id].computed}
            </td>
          );
        })}
        <td>
          {marks.mark[paper].total} {paper}
        </td>
        <td>{marks.mark[paper].grade}</td>
      </>
    );
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Subject comments - Treda</title>
        </MetaTags>

        <Container fluid>
          <Breadcrumbs title="Marks" breadcrumbItem="List" />
          <Row>
            <Col md={12} lg={12} xl={12}>
              <Card>
                <CardHeader className="justify-content-between d-flex align-items-center">
                  <CardTitle className="h4">Subject Comments</CardTitle>
                </CardHeader>
                <CardBody>
                  {loading && <Loading label="Loading marks. Please wait." />}
                  {!loading && (
                    <>
                      <Row className="mb-2">
                        <Col xl="12">
                          <ToastContainer />
                        </Col>
                      </Row>
                      <Formik
                        initialValues={initialValues}
                        enableReinitialize
                        validateOnBlur
                        onSubmit={(values: any) => handleSubmit(values)}
                      >
                        {({
                          isSubmitting,
                          setFieldValue,
                          values,
                          errors,
                          touched,
                        }) => (
                          <>
                            <Form>
                              <Row>
                                <Col lg={2}>
                                  <XSelect
                                    key="form"
                                    id="form"
                                    name={"form"}
                                    options={getForms()}
                                    label={"Class"}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={2}>
                                  <XTextBox
                                    type="text"
                                    key="stream"
                                    id="stream"
                                    name={"stream"}
                                    label={"Stream"}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={1}>
                                  <XSelect
                                    key="term"
                                    id="term"
                                    name={"term"}
                                    options={terms}
                                    label={"Term"}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={1}>
                                  <XTextBox
                                    type="text"
                                    key="year"
                                    id="year"
                                    name={"year"}
                                    label={"Year"}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={2}>
                                  <XSelect
                                    key="category"
                                    id="category"
                                    name={"category"}
                                    options={categories}
                                    label={"Report Type"}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={2}>
                                  <XSelect
                                    key="marktype_id"
                                    id="marktype_id"
                                    name={"marktype_id"}
                                    dataSrc={Endpoints.marktypes}
                                    dataType={"marktypes"}
                                    labelField={"label"}
                                    label={"Mark Type"}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={2}>
                                  <XSelect
                                    key="subject_id"
                                    id="subject_id"
                                    name={"subject_id"}
                                    dataSrc={Endpoints.subjects}
                                    dataType={"subjects"}
                                    label={"Subject"}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={2}>
                                  <XCheckbox
                                    key="rawmark"
                                    id="rawmark"
                                    name={"rawmark"}
                                    label={"Display raw marks"}
                                    value="1"
                                    includeTopPadding={true}
                                    onChange={setFieldValue}
                                    checked={values.excel == 1}
                                    size={{}}
                                  />
                                </Col>
                                <Col lg={2}>
                                  <div>&nbsp;</div>
                                  <Button
                                    disabled={false}
                                    type="submit"
                                    className="btn btn-primary"
                                  >
                                    Next
                                  </Button>
                                </Col>
                              </Row>
                              <hr />
                              {data && (
                                <>
                                  <table className="table">
                                    <thead>
                                      <tr>
                                        <th>Student ID</th>
                                        <th>Student Name</th>
                                        {data.marktypes.map(
                                          (mt: any, index: number) => {
                                            return (
                                              <th key={index}>{mt.label}</th>
                                            );
                                          }
                                        )}
                                        <th>TOTAL</th>
                                        <th>GRADE</th>
                                        <th>Comment</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {data.students.map(
                                        (student: any) => {
                                          return Object.keys(
                                            data.records[student.id.toString()] ? data.records[student.id.toString()] : {}
                                          ).map((subjectId: any) => {
                                            const marks =
                                              data.records[student.id.toString()][
                                                subjectId
                                              ];
                                            const paper = "I";
                                            const options = getAutoCommentList(
                                              marks.mark[paper].grade
                                            );
                                            return (
                                              <tr key={student.id.toString()}>
                                                <td key={`sid${student.id.toString()}`}>
                                                  {
                                                    student.studentid
                                                  }
                                                </td>
                                                <td key={`sname${student.id.toString()}`}>
                                                  {
                                                    student.fullname
                                                  }
                                                </td>
                                                {!["S5", "S6"].includes(
                                                  values.form
                                                )
                                                  ? renderAlevelRow(
                                                      student.id.toString(),
                                                      marks
                                                    )
                                                  : renderGeneralRow(
                                                      student.id.toString(),
                                                      marks
                                                    )}
                                                <td>
                                                  {options.length > 0 ? (
                                                    <>
                                                      <XSelect
                                                        id={`comment.${student.studentid}`}
                                                        name={`comment[${student.studentid}]`}
                                                        key={`comment.${student.studentId}`}
                                                        options={options}
                                                        onChange={(field: any) => {
                                                          setFieldValue(`comment[${student.studentid}]`, field.target.selectedOptions[0].value);
                                                        }}
                                                        touched={touched}
                                                        errors={errors}
                                                      />
                                                    </>
                                                  ) : (
                                                    <>
                                                      <XTextBox
                                                        id={`comment.${student.studentid}`}
                                                        type={"text"}
                                                        name={`comment[${student.studentid}]`}
                                                        key={`comment.${student.studentid}`}
                                                        onChange={(v: any) => {
                                                          setFieldValue(
                                                            `comment[${student.studentid}]`,
                                                            v
                                                          );
                                                        }}
                                                        touched={touched}
                                                        errors={errors}
                                                      />
                                                    </>
                                                  )}
                                                </td>
                                              </tr>
                                            );
                                          });
                                        }
                                      )}
                                    </tbody>
                                  </table>
                                  <hr />
                                  <Button
                                    disabled={false}
                                    type="submit"
                                    className="btn btn-primary"
                                  >
                                    SAVE
                                  </Button>
                                </>
                              )}
                            </Form>
                          </>
                        )}
                      </Formik>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SubjectComments;
