import { IField } from "../interfaces/inputs/IField";
import { Endpoints } from "../utils/Endpoints";

const categories = [
  {value: 'assesment', text: 'Assessment'},
  {value: 'exam', text: 'Exam'},
  {value: 'lower work', text: 'Lower Work'},
  {value: 'mid term', text: 'Mid term'},
  {value: 'monthly', text: 'Monthly'},
  {value: 'test', text: 'Test'},
  {value: 'weekly', text: 'Weekly'},
]

const displayTypes = [
  {value: 'list', text: 'List'},
  {value: 'table', text: 'Table'}
]

const inputTypes = [
  {value: 'text', text: 'Text'},
  {value: 'number', text: 'Number'}
]


export const RemarktypeFields: IField[] = [
  { name: "label", type: "text", label: "Name", size: {lg: 6}, position: 1 },
  { name: "category", type: "select", label: "Category", options: categories, size: {lg: 6}, position: 2 },
  { name: "parent", type: "select", label: "Belongs To", dataType: "remarktypes", dataSrc: Endpoints.remarktypes, labelField: "label", size: {lg: 6}, position: 2 },
  { name: "displaytype", type: "select", label: "Display Type", options: displayTypes, size: {lg: 6}, position: 2 },
  { name: "inputtype", type: "select", label: "Input Type", options: inputTypes, size: {lg: 6}, position: 2 },
  { name: "position", type: "number", label: "Position", size: {lg: 6}, position: 3 },
  { name: "forms", type: "text", label: "Classes", size: {lg: 6}, position: 1 },
  { name: "active", type: "checkbox" , position: 9, label: "Active", size: {lg: 3} },
].sort((a: IField, b: IField) => a.position - b.position);