import { IField } from "../interfaces/inputs/IField";
import { Endpoints } from "../utils/Endpoints";
import { getForms } from "../utils/Helpers";

const terms = [
  { value: "I", text: "I" },
  { value: "II", text: "II" },
  { value: "III", text: "III" },
];

const categories = [
  { value: "assesment", text: "Assessment" },
  { value: "exam", text: "Exam" },
];

const forms = getForms();

export const ReportFooterFields: IField[] = [
  { name: "title", type: "text", label: "Title", size: { lg: 4 }, position: 1 },
  {
    name: "form",
    type: "select",
    label: "Class",
    options: forms,
    size: { lg: 4 },
    position: 2,
  },
  {
    name: "term",
    type: "select",
    label: "Term",
    options: terms,
    size: { lg: 4 },
    position: 3,
  },
  { name: "year", type: "number", label: "Year", size: { lg: 4 }, position: 4 },

  {
    name: "category",
    type: "select",
    label: "Category",
    options: categories,
    size: { lg: 4 },
    position: 5,
  },
  {
    name: "position",
    type: "number",
    label: "Position",
    size: { lg: 4 },
    position: 6,
  },
  {
    name: "remarks",
    type: "textarea",
    label: "Remarks",
    size: { lg: 12 },
    position: 7,
  },
].sort((a: IField, b: IField) => a.position - b.position);
