import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Container,
  DropdownToggle,
  DropdownMenu, DropdownItem, UncontrolledDropdown, Button
} from "reactstrap";
import MetaTags from "react-meta-tags";

//Import Breadcrumb
import Breadcrumbs from "../../components/common/Breadcrumb";
import "../../../src/assets/scss/custom/plugins/_datatables.scss";
import { ApiCore } from "../../helpers/api_helper";
import { Loading } from "../../components/common/Loading";
import { AxiosResponse } from "axios";
import { Endpoints } from "../../utils/Endpoints";
import {toast, ToastContainer} from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import DeleteForm from "./delete-form";

const DeleteMarks = () => {

  const [loading, setLoading] = useState<boolean>(false);
  const [grid, setGrid] = useState<any>([]);
  const [selectedMarkTypes, setSelectedMarkTypes] = useState<any>([]);

  const params = new URLSearchParams(useLocation().search);

  const [initialValues, setInitialValues] = useState<any>({
  });

  const [topic, setTopic] = useState<any>(null);

  const request = new ApiCore();

  useEffect(() => {
  if (params.get("topic_id")) {
      request
        .get(Endpoints.topics + "/" + params.get("topic_id"))
        .then((response: AxiosResponse) => {
          setTopic(response.data)
          setInitialValues({topic_id: params.get("topic_id"), form: response.data.form, subject: response.data.subject_id, term: response.data.term})
        })
        .catch(error => {
          console.error(error);
        });   
  }}, []);

  const handleSubmit = (values: any) => {
    setLoading(true);
    setInitialValues(values);
    request
    .create(Endpoints.bulkDeleteMarks, values)
    .then((response: AxiosResponse) => {
      const data = response.data;
      toast.success(`${data.records} deleted successfully`);
      setLoading(false);
    })
    .catch(error => {
      setLoading(false);
      toast.error(error);
    });
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Marks - Treda</title>
        </MetaTags>

        <Container fluid>
          <Breadcrumbs title="Marks" breadcrumbItem="List" />
          <Row>
            <Col md={12} lg={12} xl={12}>
              <Card>
                <CardHeader className="justify-content-between d-flex align-items-center">
                  <CardTitle className="h4">
                    Delete Marks
                  </CardTitle>

                  <UncontrolledDropdown className="mt-4 mt-sm-0">
                    <DropdownToggle tag="a" className="btn btn-light">
                      <i className="fa fa-ellipsis-v"></i>
                    </DropdownToggle>

                    <DropdownMenu className="dropdown-menu-end">
                      <DropdownItem to="#">
                        Delete Marks
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </CardHeader>
                <CardBody>
                  {loading && <Loading label="Loading marks. Please wait." />}
                  {!loading &&
                    <>
                      <Row className="mb-2">
                        <Col xl="12">
                          <DeleteForm
                            initialValues={initialValues}
                            handleSearch={(values: any) => {
                              if (window.confirm("Do you really want to delete the marks")) {
                                handleSubmit(values);
                              }
                            }}
                            topic={topic}
                          />
                          <ToastContainer />
                        </Col>
                      </Row>
                    </>
                  }

                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>

      </div>
    </React.Fragment>
  );
};

export default DeleteMarks;