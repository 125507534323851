import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Container,
  DropdownToggle,
  DropdownMenu, DropdownItem, UncontrolledDropdown
} from "reactstrap";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import Breadcrumbs from "../../components/common/Breadcrumb";
import "../../../src/assets/scss/custom/plugins/_datatables.scss";
import { ApiCore } from "../../helpers/api_helper";
import { Loading } from "../../components/common/Loading";
import { AxiosResponse } from "axios";
import XModal from "../../components/common/XModal";
import CreateForm from "../../components/forms/CreateForm";
import { Endpoints } from "../../utils/Endpoints";
import { AutomarkcommentFields } from "../../fields/AutomarkcommentFields";

const Automarkcomments = () => {

  const [loading, setLoading] = useState<boolean>(true);
  const [automarkcomments, setAutomarkcomments] = useState<any>([]);
  const [showAutomarkcommentDialog, setShowAutomarkcommentDialog] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<any>({});

  const columns = [
    {
      dataField: "score",
      text: "Score"
    },
    {
      dataField: "comment",
      text: "Comment"
    },
    {
      dataField: "category",
      text: "Category"
    },
    {
      dataField: "",
      text: "",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, product: any) => <UncontrolledDropdown>
        <DropdownToggle className="card-drop" tag="a">
          <i className="mdi mdi-dots-horizontal font-size-18 text-muted"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem to="#" onClick={() => handleOption(product, "edit")}>
            <i className="mdi mdi-pencil font-size-16 text-success me-1"></i> Edit
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    }
  ];


  const handleOption = (item: any, type: string) => {
    if (type == "edit") {
      setInitialValues(item);
      setShowAutomarkcommentDialog(true);
    } else if (type == "new") {
      setInitialValues({});
      setShowAutomarkcommentDialog(true);
    }
  }  

  const defaultSorted: any = [
    {
      dataField: "name",
      order: "asc"
    }
  ];

  const pageOptions: any = {
    sizePerPage: 25,
    totalSize: automarkcomments.length,
    custom: true
  };

  const selectRow: any = {
    mode: "checkbox"
  };

  const request = new ApiCore();
  const { SearchBar } = Search;

  useEffect(() => {

    request.get(Endpoints.automarkcomments, {})
      .then((response: AxiosResponse) => {
        const { automarkcomments, totalRecords } = response.data;
        setAutomarkcomments(automarkcomments);
        setLoading(false);

      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleUpdate = (item: any) => {
    let found = false;
    const items = automarkcomments.map((i: any) => {
      if (i.id === item.id){
        found = true;
        return {...i, ...item};
      }

      return i;
    })
    if (!found) {
      items.push(item);
    }
    setAutomarkcomments(items);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Auto Subject Comment - Treda</title>
        </MetaTags>

        <Container fluid>
          <Breadcrumbs title="Auto Comment" breadcrumbItem="List" />

          <XModal title={"Add a comment"}
                  width={"lg"}
                  open={showAutomarkcommentDialog}
                  toggle={() => setShowAutomarkcommentDialog(!showAutomarkcommentDialog)}
                  onClose={() => setShowAutomarkcommentDialog(false)}>

            <CreateForm
              fields={AutomarkcommentFields}
              initialValues={initialValues}
              submitEndpoint={initialValues.id ? Endpoints.automarkcomments + "/" + initialValues.id : Endpoints.automarkcomments}
              submitButtonLabel={initialValues.id ? "Edit Comment" : "Add Comment"}
              debug={false}
              handleUpdate={handleUpdate}
              onClose={() => setShowAutomarkcommentDialog(false)} />

          </XModal>

          <Row>
            <Col md={12} lg={12} xl={12}>
              <Card>
                <CardHeader className="justify-content-between d-flex align-items-center">
                  <CardTitle className="h4">
                    Auto subject comments
                  </CardTitle>

                  <UncontrolledDropdown className="mt-4 mt-sm-0">
                    <DropdownToggle tag="a" className="btn btn-light">
                      <i className="fa fa-ellipsis-v"></i>
                    </DropdownToggle>

                    <DropdownMenu className="dropdown-menu-end">
                      <DropdownItem to="#" onClick={() => handleOption(null, "new")}>
                        Add Comment
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </CardHeader>
                <CardBody>
                  {loading && <Loading label="Loading a-level scores. Please wait." />}
                  {!loading &&
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={columns}
                          data={automarkcomments}
                          search
                          bootstrap4
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col lg="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar {...toolkitProps.searchProps} />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                  <BootstrapTable
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    defaultSorted={defaultSorted}
                                    classes={
                                      "table align-middle table-nowrap table-hover"
                                    }
                                    bordered={false}
                                    striped={true}
                                  />
                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  }

                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>

      </div>
    </React.Fragment>
  );
};

export default Automarkcomments;