import { IField } from "../interfaces/inputs/IField";

const categories = [
  {value: 'english', text: 'English'},
  {value: 'general', text: 'General'},
  {value: 'humanity', text: 'Humanity'},
  {value: 'subsidiary', text: 'subsidiary'},
  {value: 'mathematics', text: 'Mathematics'},
  {value: 'science', text: 'Science'},
]

const statCategories = [
  {value: 'language', text: 'Language'},
  {value: 'optional', text: 'Optional'},
  {value: 'humanity', text: 'Humanity'},
  {value: 'science', text: 'Science'},
]

export const SubjectFields: IField[] = [
  { name: "name", type: "text", label: "Name", size: {lg: 6}, position: 1 },
  { name: "category", type: "select", label: "Category", options: categories, size: {lg: 3}, position: 2 },
  { name: "abbreviation", type: "text", label: "Abbr.", size: {lg: 3}, position: 3 },
  { name: "olevelcategory", type: "select", label: "Stats. Category", options: statCategories, position: 4, size: {lg: 6} },
  { name: "position", type: "number", label: "Position", size: {lg: 3}, position: 5 },
  { name: "weeklylabel", type: "text", label: "Weekly Label", size: {lg: 3}, position: 6 },
  { name: "multiple_paper_forms", type: "text", label: "Multiple Paper Classes", size: {lg: 6}, position: 7 },
  { name: "multiple_papers", type: "checkbox", position: 8, label: "Multiple papers", size: {lg: 3} },
  { name: "active", type: "checkbox" , position: 9, label: "Active", size: {lg: 3} },
].sort((a: IField, b: IField) => a.position - b.position);