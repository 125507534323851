import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
  Button,
} from "reactstrap";
import MetaTags from "react-meta-tags";

//Import Breadcrumb
import Breadcrumbs from "../../../components/common/Breadcrumb";
import "../../../../src/assets/scss/custom/plugins/_datatables.scss";
import { ApiCore } from "../../../helpers/api_helper";
import { Loading } from "../../../components/common/Loading";
import { AxiosResponse } from "axios";
import { Endpoints } from "../../../utils/Endpoints";
import { XTextBox } from "../../../components/common/inputs/XTextBox";
import { Form, Formik } from "formik";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { XSelect } from "../../../components/common/inputs/XSelect";
import { getForms } from "../../../utils/Helpers";
import { XCheckbox } from "../../../components/common/inputs/XCheckbox";
import { options } from "../../../common/data/kanbanboards";

const Genericcomments = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<any>({});
  const [data, setData] = useState<any>(null);

  const request = new ApiCore();

  const terms = [
    { value: "I", text: "I" },
    { value: "II", text: "II" },
    { value: "III", text: "III" },
  ];

  const categories = [
    { value: "assesment", text: "Assessment" },
    { value: "exam", text: "Exam" },
  ];

  const handleSubmit = (values: any) => {
    setInitialValues(values);
    if (values.comment) {
      toast
        .promise(request.create(Endpoints.genericcomments, values), {
          pending: "Please wait..",
          success: {
            render({ data }) {
              let message: any = "";
              if (values.excel && values.excel == 1) {
                message = "Ready...";
              }
              return message;
            },
          },
          error: {
            render({ data }) {
              return data;
            },
          },
        })
        .then((response: AxiosResponse) => {
          delete values.comment;
          setInitialValues(values);
        });
    } else {
      toast
        .promise(request.get(Endpoints.genericcomments, { params: values }), {
          pending: "Please wait..",
          success: {
            render({ data }) {
              let message: any = "";
              if (values.excel && values.excel == 1) {
                message = "Ready...";
              }
              return message;
            },
          },
          error: {
            render({ data }) {
              return data;
            },
          },
        })
        .then((response: AxiosResponse) => {
          values.comment = {};

          response.data.students.map((student: any) => {
            if (response.data.records[student.id.toString()]) {
              Object.keys(response.data.records[student.id.toString()]).map(
                (subjectId: any) => {
                  const marks = response.data.records[student.id.toString()][subjectId];
                  values.comment[student.studentid] = marks.skill;
                }
              );              
            }
          })

          setInitialValues(values);
          setData(response.data);
        });
    }
  };

  const handleSearch = (values: any) => {
    delete values.comment;
    values.nc = 1;
    handleSubmit(values);
  }

  const getAutoCommentList = () => {
    let options: any = [];
    data.autocomments.map((d: any) => {
      options.push({ text: d.comment, value: d.comment });
    });
    return options;
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Generic skills - Treda</title>
        </MetaTags>

        <Container fluid>
          <Breadcrumbs title="Marks" breadcrumbItem="List" />
          <Row>
            <Col md={12} lg={12} xl={12}>
              <Card>
                <CardHeader className="justify-content-between d-flex align-items-center">
                  <CardTitle className="h4">Generic skills</CardTitle>
                </CardHeader>
                <CardBody>
                  {loading && <Loading label="Loading marks. Please wait." />}
                  {!loading && (
                    <>
                      <Row className="mb-2">
                        <Col xl="12">
                          <ToastContainer />
                        </Col>
                      </Row>
                      <Formik
                        initialValues={initialValues}
                        enableReinitialize
                        validateOnBlur
                        onSubmit={(values: any) => handleSubmit(values)}
                      >
                        {({
                          isSubmitting,
                          setFieldValue,
                          values,
                          errors,
                          touched,
                        }) => (
                          <>
                            <Form>
                              <Row>
                                <Col lg={2}>
                                  <XSelect
                                    key="form"
                                    id="form"
                                    name={"form"}
                                    options={getForms()}
                                    label={"Class"}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={2}>
                                  <XTextBox
                                    type="text"
                                    key="stream"
                                    id="stream"
                                    name={"stream"}
                                    label={"Stream"}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={1}>
                                  <XSelect
                                    key="term"
                                    id="term"
                                    name={"term"}
                                    options={terms}
                                    label={"Term"}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={1}>
                                  <XTextBox
                                    type="text"
                                    key="year"
                                    id="year"
                                    name={"year"}
                                    label={"Year"}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={2}>
                                  <XSelect
                                    key="category"
                                    id="category"
                                    name={"category"}
                                    options={categories}
                                    label={"Report Type"}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={2}>
                                  <XSelect
                                    key="subject_id"
                                    id="subject_id"
                                    name={"subject_id"}
                                    dataSrc={Endpoints.subjects}
                                    dataType={"subjects"}
                                    label={"Subject"}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={2}>
                                  <div>&nbsp;</div>
                                  <Button
                                    disabled={false}
                                    type="submit"
                                    className="btn btn-primary"
                                    onClick={(e: any) => {
                                      handleSearch(values);
                                      e.preventDefault();
                                      return false;
                                    }}
                                  >
                                    Next
                                  </Button>
                                </Col>
                              </Row>
                              <hr />
                              {data && (
                                <>
                                      {data.students.map(
                                        (student: any) => {
                                          const marks = data.records[student.id.toString()];
                                          const options = getAutoCommentList();
                                          return (
                                            <>
                                              <div><b>Student ID: </b> {student.studentid}&nbsp;&nbsp;{student.fullname}</div><hr/>
                                              { marks ? (
                                                Object.keys(marks).map((subjectId: any) => {
                                                  const mark = marks[subjectId].mark;
                                                  return (
                                                    <table className="table" key={`tb-${student.id}-${subjectId}`}>
                                                      <tbody>
                                                      {
                                                        Object.keys(mark).map((topic: any) => {
                                                          const record = mark[topic]
                                                          return (
                                                          <tr key={`tb-${student.id}-${subjectId}-${topic}`} ><td>{record.title}</td><td>{record.raw}</td></tr>
                                                          )
                                                        })
                                                      }
                                                      </tbody>
                                                    </table>
                                                  )
                                                })
                                              ) : (
                                                <></>
                                              )}
                                              {options.length > 0 ?                                                       
                                              <XSelect
                                                        id={`comment1.${student.studentid}`}
                                                        name={`comment1[${student.studentid}]`}
                                                        key={`comment1.${student.studentId}`}
                                                        options={options}
                                                        onChange={(field: any) => { 
                                                          const value = field.target.selectedOptions[0].value;
                                                          setFieldValue(
                                                            `comment[${student.studentid}]`,
                                                            value
                                                          );
                                                        }}
                                                        touched={touched}
                                                        errors={errors}
                                                      /> : <></>}
                                              <XTextBox
                                                id={`comment.${student.studentid}`}
                                                type={"text"}
                                                name={`comment[${student.studentid}]`}
                                                key={`comment.${student.studentid}`}
                                                placeholder="Generic skills"
                                                onChange={(v: any) => {
                                                  setFieldValue( `comment[${student.studentid}]`,v);
                                                }}
                                                touched={touched}
                                                errors={errors}
                                              />
                                            </>
                                          );
                                        }
                                      )}
                                  <hr />
                                  <Button
                                    disabled={false}
                                    type="submit"
                                    className="btn btn-primary"
                                  >
                                    SAVE
                                  </Button>
                                </>
                              )}
                            </Form>
                          </>
                        )}
                      </Formik>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Genericcomments;
