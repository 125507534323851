import React, { useEffect, useState } from "react";

//import Breadcrumbs
import { Loading } from "../../../components/common/Loading";

import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";

import { FieldArray, Form, Formik } from "formik";
import { XSelect } from "../../../components/common/inputs/XSelect";
import { Endpoints } from "../../../utils/Endpoints";
import { ApiCore } from "../../../helpers/api_helper";
import { AxiosError, AxiosResponse } from "axios";
import { getForms } from "../../../utils/Helpers";
import { XTextBox } from "../../../components/common/inputs/XTextBox";
import { XCheckbox } from "../../../components/common/inputs/XCheckbox";
import { XFileInput } from "../../../components/common/inputs/XFileInput";

const GridForm = (props: any) => {
  const [marktargets, setMarkTargets] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingMarktargets, setLoadingMarktargets] = useState<boolean>(false);

  const request = new ApiCore();

  useEffect(() => {
    request
      .get(Endpoints.marktargets, {})
      .then((response: AxiosResponse) => {
        const { marktargets } = response.data;
        setMarkTargets(marktargets);
        setLoadingMarktargets(false);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const terms = [
    { value: "I", text: "I" },
    { value: "II", text: "II" },
    { value: "III", text: "III" },
  ];

  const handleSubmit = (values: any) => {
    let selectedMarkTargets = marktargets;
    props.handleSearch(values, selectedMarkTargets);
  };

  return (
    <React.Fragment>
      <div>
        <Container fluid>
          {loading ||
            (loadingMarktargets && (
              <Loading label="Loading marks. Please wait." />
            ))}
          {!loading && !loadingMarktargets && (
            <>
              <Formik
                initialValues={props.initialValues}
                enableReinitialize
                validateOnBlur
                onSubmit={(values: any) => handleSubmit(values)}
              >
                {({ isSubmitting, setFieldValue, values, errors, touched }) => (
                  <>
                    <Form>
                      <Row>
                        <Col lg={2}>
                          <XSelect
                            key="form"
                            id="form"
                            name={"form"}
                            options={getForms()}
                            label={"Class"}
                            touched={touched}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={2}>
                          <XTextBox
                            type="text"
                            key="stream"
                            id="stream"
                            name={"stream"}
                            label={"Stream"}
                            touched={touched}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={1}>
                          <XSelect
                            key="term"
                            id="term"
                            name={"term"}
                            options={terms}
                            label={"Term"}
                            touched={touched}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={1}>
                          <XTextBox
                            type="text"
                            key="year"
                            id="year"
                            name={"year"}
                            label={"Year"}
                            touched={touched}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={2}>
                          <XSelect
                            key="subject"
                            id="subject"
                            name={"subject_id"}
                            dataSrc={Endpoints.subjects}
                            dataType={"subjects"}
                            label={"Subject"}
                            touched={touched}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={3}>
                          <XFileInput
                            key="file"
                            id="file"
                            name={"file"}
                            label={"Upload Excel File"}
                            onChange={(event: any) => {
                              setFieldValue(
                                "file",
                                event.currentTarget.files[0]
                              );
                            }}
                            errors={errors}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={2}>
                          <XCheckbox
                            key="excel"
                            id="excel"
                            name={"excel"}
                            label={"Download Excel"}
                            value="1"
                            includeTopPadding={true}
                            onChange={setFieldValue}
                            checked={values.excel == 1}
                            size={{}}
                          />
                        </Col>
                        <Col lg={2}>
                          <div>&nbsp;</div>
                          <Button
                            disabled={false}
                            type="submit"
                            className="btn btn-primary"
                          >
                            Next
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                    <hr />
                  </>
                )}
              </Formik>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default GridForm;
