import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Button,
} from "reactstrap";

import Breadcrumbs from "../../components/common/Breadcrumb";

import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";

import { AxiosError, AxiosResponse } from "axios";
import { ApiCore } from "../../helpers/api_helper";
import { Endpoints } from "../../utils/Endpoints";
import {
  amountToCurrency,
  dateToDbFormat,
  formattedDate,
  getTerms,
} from "../../utils/Helpers";
import { Link, useHistory, useLocation } from "react-router-dom";
import { invoiceEndDate, invoiceStartDate } from "../../utils/Helpers";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form, Formik } from "formik";
import { XSelect } from "../../components/common/inputs/XSelect";
import { XSearchableSelect } from "../../components/common/inputs/XSearchableSelect";
import { XTextBox } from "../../components/common/inputs/XTextBox";
import { ISelectOption } from "../../interfaces/inputs/ISelectOption";
import { XDatePickerField } from "../../components/common/inputs/XDatePickerField";
import { XCheckbox } from "../../components/common/inputs/XCheckbox";

const MarksList = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [forms, setForms] = useState<any>([]);
  const [subjects, setSubjects] = useState<any>([]);
  const [marks, setMarks] = useState<any>([]);
  const [topic, setTopic] = useState<any>(null);

  const history = useHistory();
  //   let initialValues: any = {};
  const [initialValues, setInitialValues] = useState<any>({});

  const params = new URLSearchParams(useLocation().search);

  const papers = [
    { value: "I", text: "I" },
    { value: "II", text: "II" },
    { value: "III", text: "III" },
    { value: "IV", text: "IV" },
    { value: "V", text: "V" },
    { value: "VI", text: "VI" },
  ];

  useEffect(() => {
    if (params.get("topic_id")) {
      request
        .get(Endpoints.topics + "/" + params.get("topic_id"))
        .then((response: AxiosResponse) => {
          setTopic(response.data);
          setInitialValues({
            topic_id: params.get("topic_id"),
            form: response.data.form,
            subject: response.data.subject_id,
            term: response.data.term,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, []);

  useEffect(() => {
    request
      .get(Endpoints.subjects, {})
      .then((response: AxiosResponse) => {
        const { subjects, totalRecords } = response.data;
        let options: any = [];
        options = subjects.map((data: any) => ({
          value: data.id,
          text: data.name,
          data: JSON.stringify(data),
        }));
        setSubjects(options);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const loadMarks = (values: any) => {
    // setInitialValues(values);
    fetchHtml(values);
  };

  const fetchHtml = (values: any) => {
    toast
      .promise(
        request.get(Endpoints.marks, {
          params: {
            form: values.form,
            term: values.term,
            year: values.year,
          },
        }),
        {
          pending: "Please wait..",
          success: {
            render({ data }) {
              return "";
            },
          },
          error: {
            render({ data }) {
              return data;
            },
          },
        }
      )
      .then((response: AxiosResponse) => {
        const { marks } = response.data;
        setMarks(marks);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const request = new ApiCore();

  const handleSubmit = (values: any) => {
    console.log(values);
    loadMarks(values);
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    request
      .get(Endpoints.getSchoolFeesDefaults, {})
      .then((response: AxiosResponse) => {
        const { forms } = response.data;
        let options: any = [];
        options = forms.map((data: any) => ({
          value: data,
          label: data,
          data: JSON.stringify(data),
        }));
        setForms(options);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content">
        <MetaTags>
          <title>Marks | Treda</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="View" breadcrumbItem="Marks" />
          <Row>
            <Col lg="12">
              <Card>
                <CardHeader className="justify-content-between d-flex align-items-center">
                  <CardTitle className="h4">Marks</CardTitle>

                  <UncontrolledDropdown className="mt-4 mt-sm-0">
                    <DropdownToggle tag="a" className="btn btn-light">
                      <i className="fa fa-ellipsis-v"></i>
                    </DropdownToggle>

                    <DropdownMenu className="dropdown-menu-end">
                      <DropdownItem
                        to="#"
                        onClick={() => {
                          history.push("/marks/create");
                        }}
                      >
                        Upload Marks
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </CardHeader>
                <CardBody>
                  {!loading && (
                    <>
                      <Formik
                        initialValues={initialValues}
                        enableReinitialize
                        validateOnBlur
                        onSubmit={(values: any) => handleSubmit(values)}
                      >
                        {({
                          isSubmitting,
                          setFieldValue,
                          values,
                          errors,
                          touched,
                        }) => (
                          <>
                            <Form>
                              <Row>
                                <Col lg={2}>
                                  <XSearchableSelect
                                    key="form"
                                    id="form"
                                    name={"form"}
                                    options={forms}
                                    label={"Class"}
                                    onChange={(value: any) => {
                                      setFieldValue("form", value.value);
                                    }}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={1}>
                                  <XTextBox
                                    id="stream"
                                    type={"text"}
                                    name={"stream"}
                                    label={"Stream"}
                                    onChange={(value: any) => {
                                      setFieldValue("stream", value.value);
                                    }}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={1}>
                                  <XSelect
                                    key="term"
                                    id="term"
                                    name={"term"}
                                    options={getTerms()}
                                    label={"Term"}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={1}>
                                  <XTextBox
                                    type="text"
                                    key="year"
                                    id="year"
                                    name={"year"}
                                    label={"Year"}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={2}>
                                  <XSelect
                                    key="subject_id"
                                    id="subject_id"
                                    name={"subject_id"}
                                    options={subjects}
                                    label={"Subject"}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={1}>
                                  <XSelect
                                    key="paper"
                                    id="paper"
                                    name={"paper"}
                                    options={papers}
                                    label={"Paper"}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={2}>
                                  <XSelect
                                    key="marktype_id"
                                    id="marktype_id"
                                    name={"marktype_id"}
                                    dataSrc={Endpoints.marktypes}
                                    dataType={"marktypes"}
                                    labelField={"label"}
                                    label={"Mark Type"}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </Col>
                                <Col lg={2}>
                                  <div>&nbsp;</div>
                                  <Button
                                    disabled={false}
                                    type="submit"
                                    className="btn btn-primary"
                                  >
                                    Next
                                  </Button>
                                </Col>
                              </Row>
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>
                                      <b>Student</b>
                                    </th>
                                    <th className="text-center">
                                      <b>Class</b>
                                    </th>
                                    <th className="text-center">
                                      <b>Term</b>
                                    </th>
                                    <th className="text-center">
                                      <b>Year</b>
                                    </th>
                                    <th className="text-center">
                                      <b>Paper</b>
                                    </th>
                                    <th>
                                      <b>Subject</b>
                                    </th>
                                    <th className="text-center">
                                      <b>User</b>
                                    </th>
                                    <th className="text-center">
                                      <b>Mark type</b>
                                    </th>
                                    <th className="text-center">
                                      <b>Mark</b>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {marks &&
                                    marks.map((mark: any) => {
                                      return (
                                        <>
                                          <tr key={mark.id}>
                                            <td>
                                              {mark.student
                                                ? mark.student.lastname +
                                                  " " +
                                                  mark.student.firstname +
                                                  " - " +
                                                  mark.student.studentid
                                                : ""}
                                            </td>
                                            <td className="text-center">
                                              {mark.form}
                                            </td>
                                            <td className="text-center">
                                              {mark.term}
                                            </td>
                                            <td className="text-center">
                                              {mark.year}
                                            </td>
                                            <td className="text-center">
                                              {mark.paper}
                                            </td>
                                            <td>
                                              {mark.sub.name
                                                ? mark.sub.name
                                                : ""}
                                            </td>
                                            <td className="text-center">
                                              {mark.user.name
                                                ? mark.user.name
                                                : " "}
                                            </td>
                                            <td className="text-center">
                                              {mark.marktype.label}
                                            </td>
                                            <td className="text-center">
                                              {mark.mark}
                                            </td>
                                          </tr>
                                        </>
                                      );
                                    })}
                                </tbody>
                              </table>
                            </Form>
                            <hr />
                          </>
                        )}
                      </Formik>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default MarksList;
