import { IField } from "../interfaces/inputs/IField";
import { Endpoints } from "../utils/Endpoints";
import { getForms } from "../utils/Helpers";

const categories = [
  {value: 'english', text: 'English'},
  {value: 'general', text: 'General'},
  {value: 'humanity', text: 'Humanity'},
  {value: 'subsidiary', text: 'subsidiary'},
  {value: 'mathematics', text: 'Mathematics'},
  {value: 'science', text: 'Science'},
]

const terms = [
  {value: 'I', text: 'I'},
  {value: 'II', text: 'II'},
  {value: 'III', text: 'III'}
]

export const ProportionFields: IField[] = [
  { name: "form", type: "select", label: "Class", options: getForms(), size: {lg: 4}, position: 1 },
  { name: "term", type: "select", label: "Term", options: terms, position: 2, size: {lg: 4} },
  { name: "year", type: "number", label: "Year", size: {lg: 4}, position: 3 },
  { name: "subject_id", type: "select", label: "Subject", dataType: "subjects", dataSrc: Endpoints.subjects, size: {lg: 4}, position: 4 },
  { name: "marktype_id", type: "select", label: "Type", dataType: "marktypes", dataSrc: Endpoints.marktypes, labelField: "label", size: {lg: 4}, position: 5 },
  { name: "percentage", type: "number", label: "Percentage", size: {lg: 4}, position: 6 },
].sort((a: IField, b: IField) => a.position - b.position);