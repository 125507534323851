import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Button,
} from "reactstrap";

import Breadcrumbs from "../../../components/common/Breadcrumb";

import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";

import { AxiosResponse } from "axios";
import { ApiCore } from "../../../helpers/api_helper";
import { Endpoints } from "../../../utils/Endpoints";
import { Link, useHistory, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SearchTopics from "./search";

const CompetenciesList = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [topics, setTopics] = useState<any>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const params = new URLSearchParams(useLocation().search);

  const history = useHistory();

  const handleOptions = async (topic: any, type: string) => {
    if (type == "record") {
      history.push("/marks/create?topic_id=" + topic.id)
    }else if (type == "delete") {
      history.push("/marks/delete?topic_id=" + topic.id)
    } else {
      history.push("/competency-detail/" + topic.id);
    }
  };

  const searchTopics = (values: any) => {
    console.log(values);
    toast
      .promise(
        request.get(Endpoints.topics, {
          params: {
            form: values.form,
            term: values.term,
            subject_id: values.subject_id,
          },
        }),
        {
          pending: "Please wait..",
          success: {
            render({ data }) {
              return "";
            },
          },
          error: {
            render({ data }) {
              return data;
            },
          },
        }
      )
      .then((response: AxiosResponse) => {
        const { topics, totalRecords } = response.data;
        setTopics(topics);
        setTotalRecords(totalRecords);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const request = new ApiCore();

  const pageOptions = {
    sizePerPage: 25,
    totalSize: totalRecords, // replace later with size(users),
    custom: true,
  };

  const defaultSorted: any = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "asc", // desc or asc
    },
  ];

  const selectRow: any = {
    mode: "checkbox",
  };

  const topicsListColumns = [
    {
      text: "Subject",
      dataField: "subject.name",
      sort: true,
    },
    {
      text: "Form",
      dataField: "form",
      sort: true,
    },
    {
      text: "Term",
      dataField: "term",
      sort: true,
    },
    {
      text: "Title",
      dataField: "title",
      sort: true,
    },
    {
      dataField: "",
      text: "",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, topic: any) => (
        <UncontrolledDropdown>
          <DropdownToggle className="card-drop" tag="a">
            <i className="mdi mdi-dots-horizontal font-size-18 text-muted"></i>
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <DropdownItem to="#" onClick={() => handleOptions(topic, "record")}>
              <i className="mdi mdi-plus font-size-16 text-danger me-1"></i>
              Record Marks
            </DropdownItem>
            <DropdownItem to="#" onClick={() => handleOptions(topic, "delete")}>
              <i className="mdi mdi-plus font-size-16 text-danger me-1"></i>
              Delete Marks
            </DropdownItem>
            <DropdownItem to="#" onClick={() => handleOptions(topic, "details")}>
              <i className="mdi mdi-info font-size-16 text-success me-1"></i>
              Details
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
    },
  ];

  useEffect(() => {
    searchTopics({
      form: "",
      subject_id: "",
      term: "",
    });
  }, []);

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content">
        <MetaTags>
          <title>Competencies | Treda</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Competencies"
            breadcrumbItem="Competencies List"
          />
          <Row>
            <Col lg="12">
              <Card>
                <CardHeader className="justify-content-between d-flex align-items-center">
                  <CardTitle className="h4">Competencies</CardTitle>

                  <UncontrolledDropdown className="mt-4 mt-sm-0">
                    <DropdownToggle tag="a" className="btn btn-light">
                      <i className="fa fa-ellipsis-v"></i>
                    </DropdownToggle>

                    <DropdownMenu className="dropdown-menu-end">
                      <DropdownItem
                        to="#"
                        onClick={() => {
                          history.push("/school/topics/new");
                        }}
                      >
                        New Competency
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </CardHeader>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={topics}
                        columns={topicsListColumns}
                        bootstrap4
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row>
                              <Col xl="12">
                                <SearchTopics
                                  handleSearch={(values: any) => {
                                    searchTopics(values);
                                  }}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    defaultSorted={defaultSorted}
                                    classes={
                                      "table align-middle table-nowrap table-hover"
                                    }
                                    bordered={false}
                                    striped={true}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CompetenciesList;
