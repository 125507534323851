import React, { useEffect, useState } from "react";

//import Breadcrumbs
import { Loading } from "../../components/common/Loading";

import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";

import { FieldArray, Form, Formik } from "formik";
import { XSelect } from "../../components/common/inputs/XSelect";
import { Endpoints } from "../../utils/Endpoints";
import { ApiCore } from "../../helpers/api_helper";
import { AxiosError, AxiosResponse } from "axios";
import { getForms } from "../../utils/Helpers";
import { XTextBox } from "../../components/common/inputs/XTextBox";
import { XCheckbox } from "../../components/common/inputs/XCheckbox";
import { XFileInput } from "../../components/common/inputs/XFileInput";

const DeleteForm = (props: any) => {
  const [studentcategories, setStudentcategories] = useState<any>([]);
  const [marktypes, setMarktypes] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingMarktypes, setLoadingMarktypes] = useState<boolean>(true);

  const request = new ApiCore();

  useEffect(() => {
    request
      .get(Endpoints.schoolStudentCategories, {})
      .then((response: AxiosResponse) => {
        const { studentcategories, totalRecords } = response.data;
        let options: any = [];
        options = studentcategories.map((data: any) => ({
          value: data.id,
          text: data.name || data.taxid,
          data: JSON.stringify(data),
        }));
        setStudentcategories(options);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    request
      .get(Endpoints.marktypes, {})
      .then((response: AxiosResponse) => {
        const { marktypes } = response.data;
        setMarktypes(marktypes);
        setLoadingMarktypes(false);
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  const terms = [
    { value: 'I', text: 'I' },
    { value: 'II', text: 'II' },
    { value: 'III', text: 'III' }
  ]

  const papers = [
    { value: 'I', text: 'I' },
    { value: 'II', text: 'II' },
    { value: 'III', text: 'III' },
    { value: 'IV', text: 'IV' },
    { value: 'V', text: 'V' },
    { value: 'VI', text: 'VI' }
  ]


  const handleSubmit = (values: any) => {
    props.handleSearch(values);
  };

  return (
    <React.Fragment>
      <div>
        <Container fluid>
          {loading || loadingMarktypes && <Loading label="Loading marks. Please wait." />}
          {!loading && !loadingMarktypes && (
            <>
              <Formik
                initialValues={props.initialValues}
                enableReinitialize
                validateOnBlur
                onSubmit={(values: any) => handleSubmit(values)}
              >
                {({ isSubmitting, setFieldValue, values, errors, touched }) => (
                  <>
                    <Form>
                      <Row>
                        <Col lg={2}>
                          <XSelect
                            key="form"
                            id="form"
                            name={"form"}
                            options={getForms()}
                            label={"Class"}
                            touched={touched}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={2}>
                          <XTextBox
                            type="text"
                            key="stream"
                            id="stream"
                            name={"stream"}
                            label={"Stream"}
                            touched={touched}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={1}>
                          <XSelect
                            key="term"
                            id="term"
                            name={"term"}
                            options={terms}
                            label={"Term"}
                            touched={touched}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={1}>
                          <XTextBox
                            type="text"
                            key="year"
                            id="year"
                            name={"year"}
                            label={"Year"}
                            touched={touched}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={1}>
                          <XSelect
                            key="paper"
                            id="paper"
                            name={"paper"}
                            options={papers}
                            label={"Paper"}
                            touched={touched}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={2}>
                          <XSelect
                            key="subject"
                            id="subject"
                            name={"subject"}
                            dataSrc={Endpoints.subjects}
                            dataType={"subjects"}
                            label={"Subject"}
                            touched={touched}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={2}>
                          <XSelect
                            key="marktype_id"
                            id="marktype_id"
                            name={"marktype_id"}
                            dataSrc={Endpoints.marktypes}
                            dataType={"marktypes"}
                            labelField={"label"}
                            label={"Mark Type"}
                            touched={touched}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={2}>
                          <div>&nbsp;</div>
                          <Button
                            disabled={false}
                            type="submit"
                            className="btn btn-primary"
                          >
                            DELETE
                          </Button>
                        </Col>
                      </Row>

                      {props.topic ? <div><hr />{props.topic.title}</div> : <></>}
                    </Form>
                    <hr />
                  </>
                )}
              </Formik>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DeleteForm;
