import React, { useState } from "react";

import {
  Card,
  CardHeader
} from "reactstrap";
import { formattedDate } from "../../utils/Helpers";

import UserChart from "../AllCharts/apex/UserChart";

const User = (props: any) => {
  const { data } = props;
    console.log(data);
  const getGraphData = () => {
    return {
      xData: Object.keys(data),
      yData: Object.values(data),
    };
  };

  return (
    <React.Fragment>
      <Card>
        <CardHeader className="bg-transparent">
          <div className="d-flex align-items-start">
            <div className="flex-grow-1">
              <h5 className="card-title mb-0">Students</h5>
            </div>
          </div>
        </CardHeader>
        <div>
          <div id="chart-area" className="apex-charts" dir="ltr">
            <UserChart data = {getGraphData()} />
          </div>
        </div>
      </Card>
    </React.Fragment>
  );
};

export default User;
