import { IField } from "../interfaces/inputs/IField";
import { Endpoints } from "../utils/Endpoints";

const categories = [
  {value: 'general', text: 'General'},
  {value: 'nc skill', text: 'Nc Skill'},
  {value: 'nc general', text: 'Nc General'},
]

export const AutoreportcommentFields: IField[] = [
  { name: "remarktype_id", type: "select", label: "Remark Type", dataSrc: Endpoints.remarktypes, dataType: "remarktypes", labelField: "lb", size: {lg: 4}, position: 1 },
  { name: "score", type: "text", label: "From", size: {lg: 4}, position: 2 },
  { name: "upper", type: "text", label: "To", size: {lg: 4}, position: 3 },
  { name: "form", type: "text", label: "Form", size: {lg: 4}, position: 4 },
  { name: "comment", type: "text", label: "Comment", size: {lg: 8}, position: 5 },
].sort((a: IField, b: IField) => a.position - b.position);