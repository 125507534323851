import axios from "axios";
import resolveBaseUrl from "../services";
import { currentTenant } from "../utils/Helpers";

//pass new generated access token here
const token = localStorage.getItem("__treda__access__token__");

const getTenantId = () => {
  const tenant = currentTenant();
  return tenant ? tenant.tenantcode : null;
}

const tenantId = getTenantId();

//apply base url for axios
const API_URL = resolveBaseUrl();

const axiosApi = axios.create({
  baseURL: API_URL
});

axiosApi.defaults.headers.common["Authorization"] = token;
if(tenantId) {
  axiosApi.defaults.headers.common["TENANTCODE"] = tenantId;
}

// content type
axiosApi.defaults.headers.post["Content-Type"] = "application/json";

// intercepting to capture errors
axiosApi.interceptors.response.use(function(response) {
  return response;
}, function(error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  let message = "Network error";
  if (error.response) {
    switch (error.response.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Please login";
        break;
      case 403:
        message = "You are not permitted to access this content.";
        break;
      case 404:
        message = "Sorry! the data you are looking for could not be found";
        break;
      case 422:
        message = error.response.data ? error.response.data : error.message;
        break;
      default:
        message = error.message || error;
    }
  }
  return Promise.reject(message);
});


class ApiCore {
  /**
   * Fetches data from given url
   */
  get = (url: string, params?: {}, responseType: any = "json") => {
    url = API_URL + url;
    axiosApi.defaults.responseType = responseType;
    return axiosApi.get(url, params);
  };

  getWithHeaders = (url: string, t: any, code: any) => {
    url = API_URL + url;
    axiosApi.defaults.headers.common["Authorization"] = t;
    if(tenantId) {
      axiosApi.defaults.headers.common["TENANTCODE"] = code;
    }
    return axiosApi.get(url, {});
  };

  /**
   * post given data to url
   */
  create = (url: string, data: {}) => {
    url = API_URL + url;
    return axiosApi.post(url, data);
  };

  createFormData = (url: string, data: any, responseType: any = "json") => {
    let params = new FormData();
    for ( var key in data ) {
      params.append(key, data[key]);
    }
    const headers = {
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": token,
        "TENANTCODE": tenantId,
      },
    };
    url = API_URL + url;
    axiosApi.defaults.responseType = responseType;
    return axiosApi.post(url, params, headers);
  };

  updateFormData = (url: string, data: any, responseType: any = "json") => {
    let params = new FormData();
    for ( var key in data ) {
      params.append(key, data[key]);
    }
    const headers = {
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": token,
        "TENANTCODE": tenantId,
      },
    };
    url = API_URL + url;
    axiosApi.defaults.responseType = responseType;
    return axiosApi.put(url, params, headers);
  };

  /**
   * Updates data
   */
  update = (url: string, data: {}) => {
    url = API_URL + url;
    return axiosApi.put(url, data);
  };

  /**
   * Delete
   */
  delete = (url: string, data?: {}) => {
    url = API_URL + url;
    return axiosApi.delete(url, { ...data });
  };

  test = () => {

  };
};

export { ApiCore };