import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Container,
  DropdownToggle,
  DropdownMenu, DropdownItem, UncontrolledDropdown, Button
} from "reactstrap";
import MetaTags from "react-meta-tags";

//Import Breadcrumb
import Breadcrumbs from "../../components/common/Breadcrumb";
import "../../../src/assets/scss/custom/plugins/_datatables.scss";
import { ApiCore } from "../../helpers/api_helper";
import { Loading } from "../../components/common/Loading";
import { AxiosResponse } from "axios";
import { Endpoints } from "../../utils/Endpoints";
import GridForm from "./grid";
import { XTextBox } from "../../components/common/inputs/XTextBox";
import { FieldArray, Form, Formik } from "formik";
import {toast, ToastContainer} from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { XSelect } from "../../components/common/inputs/XSelect";
import { getForms } from "../../utils/Helpers";
import { XCheckbox } from "../../components/common/inputs/XCheckbox";

const AvgMarksheet = () => {
  const [initialValues, setInitialValues] = useState<any>({
    excel: 1
  });
  const [marktypes, setMarktypes] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingMarktypes, setLoadingMarktypes] = useState<boolean>(true);

  const [data, setData] = useState<any>(null);
  const ref = React.useRef<HTMLIFrameElement>(null);

  const request = new ApiCore();

  useEffect(() => {
    request
      .get(Endpoints.marktypes, {})
      .then((response: AxiosResponse) => {
        const { marktypes  } = response.data;
        setMarktypes(marktypes);
        setLoadingMarktypes(false);
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);


  const handleSubmit = (values: any) => {
    toast.promise(request.get(Endpoints.avgMarkSheet, {params: values}, values.html ? "json" : "arraybuffer"), {
      pending: "Please wait..",
      success: {
        render({data}) {
          let message: any = "";
          message = "The marksheet is ready."
          return message;
        }
      },
      error: {
        render({data}) {
          return data;
        }
      }
    }).then((response: AxiosResponse) => {
        const fileExt = values.excel ? "xlsx" : "xlsx";
        const type = response.headers['content-type']
        const blob = new Blob([response.data], {type: type})
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `marksheet-${values.form}-${values.year}.${fileExt}`
        link.click()
    });
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Mark sheet - Treda</title>
        </MetaTags>

        <Container fluid>
          <Breadcrumbs title="Marks" breadcrumbItem="List" />
          <Row>
            <Col md={12} lg={12} xl={12}>
              <Card>
                <CardHeader className="justify-content-between d-flex align-items-center">
                  <CardTitle className="h4">
                    Average Marksheet
                  </CardTitle>

                  <UncontrolledDropdown className="mt-4 mt-sm-0">
                    <DropdownToggle tag="a" className="btn btn-light">
                      <i className="fa fa-ellipsis-v"></i>
                    </DropdownToggle>

                    <DropdownMenu className="dropdown-menu-end">
                      <DropdownItem to="/marks/create">
                        Add Marks
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </CardHeader>
                <CardBody>
                  {loading || loadingMarktypes && <Loading label="Loading marks. Please wait." />}
                  {!loading && !loadingMarktypes && 
                    <>
                      <Row className="mb-2">
                        <Col xl="12">
                          <ToastContainer />
                        </Col>
                      </Row>
                        <Formik
                          initialValues={initialValues}
                          enableReinitialize
                          validateOnBlur
                          onSubmit={(values: any) => handleSubmit(values)}
                        >
                          {({ isSubmitting, setFieldValue, values, errors, touched }) => (
                  <>
                    <Form>
                      <Row>
                        <Col lg={2}>
                          <XSelect
                            key="form"
                            id="form"
                            name={"form"}
                            options={getForms()}
                            label={"Class"}
                            touched={touched}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={1}>
                          <XTextBox
                            type="text"
                            key="year"
                            id="year"
                            name={"year"}
                            label={"Year"}
                            touched={touched}
                            errors={errors}
                          />
                        </Col>
                        <FieldArray
                          name="marktype_ids"
                          render={arrayHelpers => (
                            <>
                            {marktypes && marktypes.map((item: any, index: number) => {
                              return <Col lg={1} key={index}>
                                <XCheckbox
                                  key={`marktypes.${index}`}
                                  id={`marktypes.${index}`}
                                  name={`marktype_ids`}
                                  label={item.label}
                                  value={item.id}
                                  checked={values.marktype_ids && values.marktype_ids.includes(item.id)}
                                  includeTopPadding={true}
                                  onChange={(field: any, value: any) => {
                                    if (value && value > 0) {
                                      arrayHelpers.push(item.id)
                                    } else {
                                      const idx = values.marktype_ids.indexOf(item.id);
                                      arrayHelpers.remove(idx);
                                    }
                                  }}
                                  size={{}}
                                />
                              </Col>
                            })}
                            </>
                          )}  
                        />               
                        <Col lg={1}>
                          <div>&nbsp;</div>
                          <Button
                            disabled={false}
                            type="submit"
                            className="btn btn-primary"
                          >
                            Next
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                    <hr/>
                  </>
                )}
                      </Formik>
                    </>
                  }

                </CardBody>
              </Card>
            </Col>
          </Row>
          {data && (
          <Row>
            <Col md={12} lg={12} xl={12}>
              <Card>
                <CardBody>
                <iframe ref={ref} srcDoc={data} width="100%" height="800px"  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          )}
        </Container>

      </div>
    </React.Fragment>
  );
};

export default AvgMarksheet;