import { IField } from "../interfaces/inputs/IField";
import { Endpoints } from "../utils/Endpoints";
import { getForms } from "../utils/Helpers";

const terms = [
  { value: "I", text: "I" },
  { value: "II", text: "II" },
  { value: "III", text: "III" },
];

const forms = getForms();

export const SubjectPaperFields: IField[] = [
  {
    name: "form",
    type: "select",
    label: "Class",
    options: forms,
    size: { lg: 4 },
    position: 1,
  },
  {
    name: "term",
    type: "select",
    label: "Term",
    options: terms,
    size: { lg: 4 },
    position: 2,
  },
  { name: "year", type: "text", label: "Year", size: { lg: 4 }, position: 3 },
  {
    name: "subject_id",
    type: "select",
    label: "Subject",
    dataType: "subjects",
    dataSrc: Endpoints.subjects,
    size: { lg: 4 },
    position: 4,
  },
  {
    name: "papers",
    type: "number",
    label: "Papers",
    size: { lg: 4 },
    position: 5,
  },
].sort((a: IField, b: IField) => a.position - b.position);
