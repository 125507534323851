import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Container,
  DropdownToggle,
  DropdownMenu, DropdownItem, UncontrolledDropdown, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText
} from "reactstrap";
import MetaTags from "react-meta-tags";
import { Link, useHistory } from "react-router-dom";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import Breadcrumbs from "../../components/common/Breadcrumb";
import "../../../src/assets/scss/custom/plugins/_datatables.scss";
import { ApiCore } from "../../helpers/api_helper";
import { Loading } from "../../components/common/Loading";
import { AxiosResponse } from "axios";
import XModal from "../../components/common/XModal";
import CreateForm from "../../components/forms/CreateForm";
import { TenantFields } from "../../fields/TenantFields";
import { Endpoints } from "../../utils/Endpoints";
import { ITenant } from "../../interfaces/ITenant";
import { Divider } from "@material-ui/core";
import UpdateForm from "../../components/forms/UpdateForm";
import { Urls } from "../../routes/Urls";
import { TenantSchema } from "../../schemas/TenantSchema";

const Tenants = () => {

  const [loading, setLoading] = useState<boolean>(true)
  const [loadingGroups, setLoadingGroups] = useState<boolean>(true)
  const [tenants, setTenants] = useState<any>([])
  const [groups, setGroups] = useState<any>([])
  const [showTenantDialog, setShowTenantDialog] = useState<boolean>(false)
  const [showTenantEditDialog, setShowTenantEditDialog] = useState<boolean>(false)
  const [showTenantGroupDialog, setShowTenantGroupDialog] = useState<boolean>(false)
  const [tenant, setTenant] = useState<any>()

  const history = useHistory()

  const handleTenantEdit = async (id: string) => {
    const { data } = await request.get(`${Endpoints.tenants}/${id}`, {})
    setTenant(data)
    setShowTenantEditDialog(true)
  }

  const columns = [
    {
      dataField: "name",
      text: "Name"
    },
    {
      dataField: "category",
      text: "Type"
    },
    {
      dataField: "email",
      text: "Email Address"
    },
    {
      dataField: "tenantcode",
      text: "Code"
    },
    {
      dataField: "tin",
      text: "TIN"
    },
    {
      dataField: "efrist",
      text: "EFRIS"
    },
    {
      dataField: "",
      text: "",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, tenant: any) => <UncontrolledDropdown>
        <DropdownToggle className="card-drop" tag="a">
          <i className="mdi mdi-dots-horizontal font-size-18 text-muted"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem to="#" onClick={() => handleTenantEdit(tenant.id)}>
            <i className="mdi mdi-pencil font-size-16 text-success me-1"></i>Edit
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    }
  ];

  const defaultSorted: any = [
    {
      dataField: "name",
      order: "asc"
    }
  ];

  const pageOptions: any = {
    sizePerPage: 25,
    totalSize: tenants.length,
    custom: true
  };

  const selectRow: any = {
    mode: "checkbox"
  };

  const request = new ApiCore();
  const { SearchBar } = Search;

  useEffect(() => {

    request.get(Endpoints.tenants, {})
      .then((response: AxiosResponse) => {
        const { tenants, totalRecords } = response.data;

        setTenants(tenants);
        setLoading(false);

      })
      .catch((error) => {
        console.error(error);
      });
  }, []);


  const initialValues: ITenant = {
    name: "",
    tenantcode: ""
  };

  const handleUpdate = (tenant: ITenant) => {

    const updatedTenants = tenants.map((p: ITenant) => {
      if (p.id === tenant.id){
        return {...p, ...tenant}
      }

      return p
    })

    setTenants(updatedTenants)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Tenants - Treda</title>
        </MetaTags>

        <Container fluid>
          <Breadcrumbs title="Inventory" breadcrumbItem="Tenants" />

          <XModal title={"Add a tenant"}
                  width={"lg"}
                  open={showTenantDialog}
                  toggle={() => setShowTenantDialog(!showTenantDialog)}
                  onClose={() => setShowTenantDialog(false)}>

            <CreateForm
              fields={TenantFields}
              initialValues={initialValues}
              submitEndpoint={Endpoints.tenants}
              submitButtonLabel={"Create Tenant"}
              validationSchema={TenantSchema}
              debug={false}
              handleUpdate={handleUpdate}
              onClose={() => setShowTenantDialog(false)} />

          </XModal>

          <XModal title={"Edit a tenant"}
                  width={"lg"}
                  open={showTenantEditDialog}
                  toggle={() => setShowTenantEditDialog(!showTenantEditDialog)}
                  onClose={() => setShowTenantEditDialog(false)}>

            <UpdateForm
              fields={TenantFields}
              initialValues={tenant}
              submitEndpoint={Endpoints.tenants}
              submitButtonLabel={"Save Changes"}
              validationSchema={TenantSchema}
              debug={true}
              handleUpdate={handleUpdate}
              onClose={() => setShowTenantEditDialog(false)} />

          </XModal>

          <Row>
            <Col md={12} lg={12} xl={12}>
              <Card>
                <CardHeader className="justify-content-between d-flex align-items-center">
                  <CardTitle className="h4">
                    Tenants
                  </CardTitle>

                  <UncontrolledDropdown className="mt-4 mt-sm-0">
                    <DropdownToggle tag="a" className="btn btn-light">
                      <i className="fa fa-ellipsis-v"></i>
                    </DropdownToggle>

                    <DropdownMenu className="dropdown-menu-end">
                      <DropdownItem to="#" onClick={() => setShowTenantDialog(true)}>
                        Add tenant
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>


                </CardHeader>
                <CardBody>
                  {loading && <Loading label="Loading tenants. Please wait." />}
                  {!loading &&
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={columns}
                          data={tenants}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col lg="12">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar style={{width: '100%'}} {...toolkitProps.searchProps} />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      // responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      selectRow={selectRow}
                                      classes={"table table-hover table-check align-middle table-nowrap"}
                                      headerWrapperClasses={"bg-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />
                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  }

                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>

      </div>
    </React.Fragment>
  );
};

export default Tenants;